import moment from "moment";
import 'moment/locale/pt-br';
import { Component } from "react";
import DashboardService from "../../services/dashboard.service";
import InstructorService from "../../services/instructor.service";
import StatisticService from "../../services/statistics.service";
import ClassService from "../../services/class.service";
import Loading from "../Loading/Loading";
import ExportStatistics from "./ExportStatistics/ExportStatistics";

import './Statistics.scss';
import TableInfo from "./Table/TableInfo";
import TableMissions from "./Table/TableMissions";
import { buildMissions } from "../../utils/statistics";
import { buildGeneralDataRows } from "./utils/buildGeneralDataRows";


class Statistics extends Component {
  limitDateWorkshops = moment("2022-05-07")
  component = this

  constructor(props) {
    super(props)
    this.state = {
      classes: [],
      instructors: [],

      general: [],
      powers: [],
      missions: [],
      participantsByAge: [],
      participantsByAgeRange: [],
      participantsByGender: [],

      generalData: {},
      powersData: [],
      missionsData: [],
      participantsByAgeData: [],
      participantsByAgeRangeData: [],
      participantsByGenderData: [],

      generalFilteredData: {},
      powersFilteredData: [],
      missionsFilteredData: [],
      participantsByAgeFilteredData: [],
      participantsByAgeRangeFilteredData: [],
      participantsByGenderFilteredData: [],

      inBrazil: 0,
      outOfBrazil: 0,
      inBrazilByWeek: 0,
      outOfBrazilByWeek: 0,
      trainingCompleted: 0,
      trainingCompletedFiltered: 0,

      dateStart: '',
      dateEnd: '',
      currentDate: '',
      startOfYear: '',
      loading: false,
      lastCampaignClassWorkshopDateTime: ''
    }
  }

  componentDidMount = async () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
    
    let startOfYear = moment().startOf('year').utc().format('YYYY-MM-DD')
    let currentDate = moment().utc().format('YYYY-MM-DD');

    let query = {
      classes: {
        start: startOfYear,
        end: currentDate,
      },
      instructors: {
        creationDateStart: startOfYear,
        creationDateEnd: currentDate
      }
    }

    await this.setState({
      loading: true
    })

    // General
    const general = await StatisticService.general(query.classes);

    //Powers and Missions
    const powers = await StatisticService.countPowerAssignments(query.classes);
    const missions = await StatisticService.countMissionAssignments(query.classes);

    // Classes
    const classes = await DashboardService.getReport(query.classes);

    //Instructos
    const instructors = await InstructorService.search(query.instructors);
    const { inBrazil, outOfBrazil } = this.getInstructorsByCountry(instructors.result);
    const trainingCompleted = this.getTotalTrainingCompleted(instructors.result)

    //Participants
    const { participantsByAge, participantsByAgeRange } = this.getParticipantsData(classes.result);
    const participantsByGenderData = await StatisticService.participantsByGender(query.classes);

    // DateTime of last workshop of campaign classes
    const { lastCampaignClassWorkshopDateTime } = await ClassService.getLastWorkshopOfCampaignClasses()


    await this.setState({
      //Infos
      classes: classes.result,
      generalData: general,
      powersData: powers,
      missionsData: missions,
      participantsByGenderData,
      participantsByAgeData: participantsByAge,
      participantsByAgeRangeData: participantsByAgeRange,
      inBrazil,
      outOfBrazil,
      trainingCompleted,
      //date 
      dateStart: moment().startOf('week').add(1, 'days').utc().format('YYYY-MM-DD'),
      currentDate,
      startOfYear: moment().startOf('year').format('DD/MM/YYYY'),
      lastCampaignClassWorkshopDateTime
    });

    await this.search();
    this.setRows();
  }

  setRows = async () => {
    const missionsSinceStartOfTheYear = this.state.missionsData.result;
    const missionsFilteredByDate = this.state.missionsFilteredData.result;

    const buildGeneralRows = buildGeneralDataRows.bind(this.component)

    this.setState({
      general: buildGeneralRows(),
      participantsByGender: this.buildParticipantsByGender(),
      participantsByAge: this.buildParticipantsByAge(
        this.state.participantsByAgeData,
        this.state.participantsByAgeFilteredData
      ),
      participantsByAgeRange: this.buildParticipantsByAge(
        this.state.participantsByAgeRangeData,
        this.state.participantsByAgeRangeFilteredData
      ),
      powers: this.buildPowers(),
      missions: buildMissions(missionsSinceStartOfTheYear, missionsFilteredByDate),
      instructors: this.buildInstructors(),
      loading: false
    })
  }

  // Informações - gerais

  getByDate = async () => {

    await this.setState({
      loading: true
    })

    let query = {
      start: this.state.dateStart,
      end: this.state.dateEnd === '' ? this.state.currentDate : this.state.dateEnd,
    }

    let generalFilteredData = await StatisticService.general(query);
    
    let powersFilteredData = await StatisticService.countPowerAssignments(query);


    let missionsFilteredData = await StatisticService.countMissionAssignments(query);

    let classes = await DashboardService.getReport(query);

    const participantsByGenderFilteredData = await StatisticService.participantsByGender(query);
    let participants = this.getParticipantsData(classes.result);

    await this.setState({
      generalFilteredData,
      powersFilteredData,
      missionsFilteredData,
      participantsByGenderFilteredData,
      participantsByAgeFilteredData: participants.participantsByAge,
      participantsByAgeRangeFilteredData: participants.participantsByAgeRange
    })

    await this.setRows();

  }

  // Informações - instrutores

  validatedDate = date => {
    let dateEnd = this.state.dateEnd === '' ? this.state.currentDate : this.state.dateEnd;
    return (moment(date).isBetween(this.state.dateStart, dateEnd) || moment(date).isSame(this.state.dateStart) || moment(date).isSame(dateEnd));
  }

  getInstructorsByCountry = (instructors) => {
    const country = ["Brasil", "Brazil"];
    let inBrazil = instructors.filter(instructor => country.includes(instructor.address.country));
    let outOfBrazil = instructors.filter(instructor => !country.includes(instructor.address.country));

    return {
      inBrazil: inBrazil.length,
      outOfBrazil: outOfBrazil.length
    }
  }

  filteredByDate = async () => {

    let query = {
      creationDateStart: this.state.dateStart,
      creationDateEnd: this.state.dateEnd === '' ? this.state.currentDate : this.state.dateEnd,
    }

    let instructors = await InstructorService.search(query);

    let trainingCompleted = instructors.result.filter(instructor => this.validatedDate(instructor.trainingCompletedAt));
    let { inBrazil, outOfBrazil } = this.getInstructorsByCountry(instructors.result);

    await this.setState({
      inBrazilByWeek: inBrazil,
      outOfBrazilByWeek: outOfBrazil,
      trainingCompletedFiltered: trainingCompleted.length
    })
  }

  getTotalTrainingCompleted = (instructors) => {
    let startOfYear = moment().startOf('year').utc().format('YYYY-MM-DD');
    let currentDate = moment().utc().format('YYYY-MM-DD');

    let trainingCompleted = instructors.filter(instructor => {
      return moment(instructor.trainingCompletedAt).isBetween(startOfYear, currentDate)
    });

    return trainingCompleted.length;
  }

  // Informações - participants

  getParticipantsData = (classes) => {

    let qtdParticipants = 0;
    let ages = [];

    // Pegando dados quantitativos
    for (const classInfo of classes) {
      qtdParticipants += classInfo.participants.length
      let ageAuxiliar = moment.min([moment(), moment(classInfo.createdAt)]);

      for (const participant of classInfo.participants) {

        let age = moment.duration(ageAuxiliar.diff(participant.born)).get('years');

        if (age >= 10 && age <= 18) {
          ages.push(age);
        }

      }
    }
    // Separar por idade
    let participantsByAge = this.getParticipantsByAge(ages, qtdParticipants);
    let participantsByAgeRange = this.getParticipantsByAge(ages, ages.length);


    let outOfAgeParticipants = 0;
    if (qtdParticipants !== 0) {
      outOfAgeParticipants = (((qtdParticipants - ages.length) * 100) / qtdParticipants).toFixed(2);
    }
    participantsByAge.push(Array.of(`Fora da escala (10 a 18 anos)`, `${(String(outOfAgeParticipants)).replace(".", ",")}%`))


    return { participantsByAge, participantsByAgeRange };
  }

  getParticipantsByAge = (ages, totalParticipants) => {
    let participantsByAge = [], ageMax = 18;

    for (let index = 10; index <= ageMax; index++) {
      let qtd = ages.filter(age => age === index).length;
      if (qtd === 0) {
        qtd = 0;
      } else {
        qtd = ((qtd * 100) / totalParticipants).toFixed(2);
      }
      participantsByAge.push(Array.of(`${index} anos`, `${(String(qtd)).replace(".", ",")}%`));
    }

    return participantsByAge;
  }

  buildParticipantsByGender = () => {
    let participantByGender = this.state.participantsByGenderData.gender;
    let filtered = this.state.participantsByGenderFilteredData.gender;

    let dump = [];

    dump.push(
      Array.of(
        participantByGender.female.description,
        filtered.female.inPercentage,
        participantByGender.female.inPercentage
      ));
    dump.push(
      Array.of(
        participantByGender.male.description,
        filtered.male.inPercentage,
        participantByGender.male.inPercentage
      ));
    dump.push(
      Array.of(
        participantByGender.other.description,
        filtered.other.inPercentage,
        participantByGender.other.inPercentage
      ));


    return dump;
  }

  buildParticipantsByAge = (participantsByAge, participantsByAgeFiltered) => {
    let dump = participantsByAge.map(element => {
      return [element[0], "-", element[1]]
    });


    if (participantsByAgeFiltered.length === 0)
      return dump

    dump = participantsByAgeFiltered.map(element => {
      let participants = participantsByAge.filter(e => e[0] === element[0])
      return [
        element[0],
        element[1],
        participants[0][1]
      ];
    })


    return dump;
  }

  buildPowers = () => {

    let powers = this.state.powersData.result;
    let filtered = this.state.powersFilteredData.result;

    powers.sort((p1, p2) => {
      return p1.power.pt > p2.power.pt ? 1 : -1;
    })

    filtered.sort((p1, p2) => {
      return p1.power.pt > p2.power.pt ? 1 : -1;
    })

    let dump = powers.map(element => [element.power.pt, "-", element.amount]);

    if (filtered.length === 0)
      return dump

    dump = filtered.map(element => {
      let data = powers.filter(e => e.power.pt === element.power.pt)
      return [
        data[0].power.pt,
        element.amount,
        data[0].amount
      ];
    })

    return dump

  }

  buildInstructors = () => {
    let dump = []

    dump.push(Array.of("No Brasil", this.state.inBrazilByWeek, this.state.inBrazil))
    dump.push(Array.of("Fora do Brasil", this.state.outOfBrazilByWeek, this.state.outOfBrazil))

    return dump;
  }

  //Eventos

  handleInputChange = async (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    const valueDefault = moment().startOf('week').add(1, 'days').utc().format('YYYY-MM-DD');

    await this.setState({
      [name]: value.length === 0 ? valueDefault : value
    });
  }

  search = async () => {
    await this.filteredByDate();
    await this.getByDate();
  }

  formatDate = () => {

    return `${moment(this.state.dateStart).utc().format('DD/MM/YYYY')} a ${moment(this.state.dateEnd === '' ?
      this.state.currentDate :
      this.state.dateEnd).utc().format('DD/MM/YYYY')}`
  }

  loading = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-md-12 loading">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <p className="text-loading">Carregando</p>
        </div>
      </div>
    )
  }

  renderInfo = () => {
    return (
      <div className="row">
        <div className="col-md-8">

          <nav>
            <div className="nav nav-pills mb-3" id="nav-tab" role="tablist">
              <a className="nav-link active" data-toggle="tab" href="#nav-general" role="tab">Geral</a>
              <a className="nav-link" data-toggle="tab" href="#nav-participants-gender" role="tab">Participantes por gênero</a>
              <a className="nav-link" data-toggle="tab" href="#nav-participants-age" role="tab">Participantes por idade</a>
              <a className="nav-link" data-toggle="tab" href="#nav-powers" role="tab">Poderes</a>
              <a className="nav-link" data-toggle="tab" href="#nav-missions" role="tab">Missões</a>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-general" role="tabpanel">
              {/*Informações gerais*/}
              <TableInfo items={this.state.general} date={this.formatDate()} />
            </div>

            <div className="tab-pane fade" id="nav-participants-gender" role="tabpanel">
              <TableInfo items={this.state.participantsByGender} date={this.formatDate()} />
            </div>

            <div className="tab-pane fade mt-5" id="nav-participants-age" role="tabpanel">

              <h6 className="text-center title">Participantes general</h6>
              <TableInfo items={this.state.participantsByAge} date={this.formatDate()} />

              <h6 className="text-center title">Somente de 10 a 18 anos</h6>
              <TableInfo description={"Somente de 10 a 18 anos"} items={this.state.participantsByAgeRange} date={this.formatDate()} />
            </div>

            <div className="tab-pane fade" id="nav-powers" role="tabpanel">
              <TableInfo items={this.state.powers} date={this.formatDate()} />
            </div>

            <div className="tab-pane fade" id="nav-missions" role="tabpanel">
              <TableMissions items={this.state.missions} date={this.formatDate()} />
            </div>
          </div>


        </div>

        <div className="col-md-4">
          <h6 className="title text-center">Cadastro de instrutores no App</h6>
          <hr />

          <div className="statistic-block bg-green">

            <div className="row">
              <div className="col-sm-6 border-right">
                <span className="statistic-number">{this.state.inBrazilByWeek}</span>
                <span className="statistic-text">
                  {this.formatDate()}
                </span>
              </div>

              <div className="col-sm-6">
                <span className="statistic-number">{this.state.inBrazil}</span>
                <span className="statistic-text">desde {this.state.startOfYear}</span>
              </div>

            </div>
          </div>

          <div className="statistic-block bg-blue">
            <div className="row">
              <div className="col-sm-6 border-right">
                <span className="statistic-number">{this.state.outOfBrazilByWeek}</span>
                <span className="statistic-text">
                  {this.formatDate()}
                </span>
              </div>
              <div className="col-sm-6">
                <span className="statistic-number">{this.state.outOfBrazil}</span>
                <span className="statistic-text">desde {this.state.startOfYear}</span>
              </div>
            </div>
          </div>

          <div className="container mt-4">
            <p><span className="dot bg-green"></span> No Brasil</p>
            <p><span className="dot bg-blue"></span> Fora do Brasil</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Estatísticas</h1>
        </div>

        <div className="row mb-5">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Data inicial:</label>
                  <input type="date" className="form-control" name="dateStart"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Data final:</label>
                  <input type="date" className="form-control" name="dateEnd"
                    onChange={this.handleInputChange} />

                </div>
              </div>

            </div>


          </div>

          <div className="col-md-4 align-self-center">
            <button
              className="button-full btn-save mt-4"
              onClick={this.search}
              type="button">
              <i className="fa fa-search"></i> Pesquisar
            </button>

            <ExportStatistics
              general={this.state.general}
              powers={this.state.powers}
              participantsByAge={this.state.participantsByAge}
              participantsByAgeRange={this.state.participantsByAgeRange}
              participantsByGender={this.state.participantsByGender}
              instructors={this.state.instructors}
              missions={this.state.missions}
              dateFiltered={this.formatDate()}
            />
          </div>
        </div>

  
        <Loading status={this.state.loading}>
          {this.renderInfo()}
        </Loading>

      </div>

    )
  }

}

export default Statistics;
