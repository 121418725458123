import { createContext, useEffect, useState } from 'react';
import './Pagination.scss';
import Searchbox from './components/Searchbox/Searchbox';
import ShowData from './components/ShowData/ShowData';
import PageButtonContainer from './components/PageButtonContainer/PageButtonContainer';

export const PaginationContext = createContext()

const PaginationWithSearch = ({
    data,
    children,
    itemsPerPage = 10
}) => {
    const [dataFiltered, setDataFiltered] = useState(data)
    const [currentPage, setCurrentPage] = useState(1)
    const slice = [
        (currentPage - 1) * itemsPerPage, 
        (currentPage * itemsPerPage) + itemsPerPage
    ]

    useEffect(() => {
        const savedFilter = JSON.parse(localStorage.getItem('instructor-search-filter'))
        const savedCurrentPage = Number(localStorage.getItem('instructor-current-page'))

        if (savedFilter) {
            setDataFiltered(savedFilter)
        }
        if (savedCurrentPage) {
            setCurrentPage(savedCurrentPage)
        }
    }, [])

    let pages =
      Math.ceil(dataFiltered.length / itemsPerPage) > 0
        ? Math.ceil(dataFiltered.length / itemsPerPage)
        : 1;

    const pageItems = new Array(pages).fill(0).map((_, index) => index + 1)

    const value = { 
        initialData: data,
        data: dataFiltered,
        setData: setDataFiltered,
        currentPage, 
        setCurrentPage, 
        slice, 
        pageItems,
    }

    return (
        <PaginationContext.Provider value={value}>
            <div className='pagination-with-search-container'>{children}</div>
        </PaginationContext.Provider>
    )
}


PaginationWithSearch.Buttons = PageButtonContainer
PaginationWithSearch.Data = ShowData
PaginationWithSearch.Searchbox = Searchbox

export default PaginationWithSearch