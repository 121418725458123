import popupChooseNewExpireDate from "./popupChooseNewExpireDate";
import { compareAsc } from 'date-fns';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InstructorService from "../../../services/instructor.service";
import { navigate } from "../../../services/navigation.service";

const MySwal = withReactContent(Swal);

const handleEditLicenseExpireDate = async (
    instructor,
    newDate,
    licenseExpireIn,
    setInstructors
  ) => {
    const onClosePopup = () => {      
      if (!window.location.href.includes("licenses")) {

        const navigator = navigate.bind(this.component);
        navigator({ goBack: true });
      }
    };

    const newInstructor = {
      ...instructor,
    };
    newInstructor.institution.licenseExpireIn = newDate;

    const compareDates = compareAsc(new Date(), new Date(newDate));

    if (compareDates === -1) {
      await InstructorService.edit(instructor._id, newInstructor);
      await InstructorService.changeLicense(instructor._id, { status: true });

      const instructors = await InstructorService.getAll();
      setInstructors(instructors);

      onClosePopup()
    } else {
      MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        confirmButtonText: "CONCLUIR",
        customClass: {
          confirmButton: "confirm-button-class",
        },
        icon: "error",
        reverseButtons: true,
        showCloseButton: true,
        title: <span style={{ color: "#FFC107" }}>Erro!</span>,
        html: (
          <p style={{ color: "#000" }}>
            Escolha uma data posterior ao dia de hoje.
          </p>
        ),
      }).then(() => {
        popupChooseNewExpireDate(instructor, licenseExpireIn);
      });
    }
  };

  export default handleEditLicenseExpireDate;