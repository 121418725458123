import React, { Component } from "react";
import Toggle from "react-toggle";
import "./Quiz.scss";

import ReactExport from "react-data-export";

import QuizService from "../../services/quiz.service";
import TrainingService from "../../services/training.service";
import PowerService from "../../services/power.service";
import ParticipantService from "../../services/participant.service"
import SecurityService from "../../services/security.service";
import Loading from '../Loading/Loading';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AuthContext } from "../../context/authContext";
import { navigate, navigateUsingAchor } from "../../services/navigation.service";
const MySwal = withReactContent(Swal)


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Quiz extends Component {
  static contextType = AuthContext

  constructor() {
    super();
    this.state = {
      quizzes: [],
      trainings: [],
      powers: [],
      participants: [],
      loading: true,
    };
  }

  componentDidMount = async () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
    
    let quizzes = await QuizService.getAll();
    let trainings = await TrainingService.getAll();
    let powers = await PowerService.getAll();
    let participants = await ParticipantService.getAll();
    
    powers = powers.map(power => {
      return {
        _id: power._id,
        ...power.pt
      }
    })
    trainings = trainings.map(training => {
      return {
        _id: training._id,
        ...training.pt
      }
    })

    quizzes = quizzes.map(quiz => {
      for (let power of powers) {
        if (power._id == quiz.training) {
          quiz.training = power.name;
          return quiz;
        }
      }
      for (let training of trainings) {
        if (training._id == quiz.training) {
          quiz.training = training.name;
          return quiz;
        }
      }
      return quiz;
    })

    participants = participants.map((participant) => {
      return {
        _id: participant._id,
        ...participant.gameCompleted,
        class: participant.class,
        name: participant.name,
        gameCompleted: participant.gameCompleted
      }
    })

    await this.setState({
      quizzes: quizzes,
      trainings: trainings,
      powers: powers,
      participants: participants,
      loading: false
    })
  }

  renderQuizzes(quizzes) {
    return quizzes.map((item, index) => (
      <tr key={index}>
        <td>{item.type == "instrutor" ? "Treinamento" : "Quizzes"}</td>
        <td>{item.training}</td>
        <td>{item.pt.statement.substring(0, 100) + '...'}</td>
        <td>{item.pt.answers.length}</td>
        <td>
          <Toggle
            id={item._id}
            defaultChecked={item.active}
            onChange={this.changeActive.bind(this)}
          />
        </td>
        <td>
          <a 
            className="btn-edit" 
            href={`/quizzes/${item._id}`}
            onClick={(e) => navigateUsingAchor(e, this)}
          >
            editar
          </a>
          {/* {SecurityService.isMaster() ?
            <a
              className="ml-3 btn-remove"
              href="javascript:void(0)"
              onClick={() => this.removeQuiz(item._id)}
            >
              remover
            </a> : <></>
          } */}
        </td>
      </tr>
    ))
  }

  renderInstructorQuizzes() {
    const quizzes = this.state.quizzes.filter(item => item.type === 'instrutor');
    return this.renderQuizzes(quizzes);
  }

  renderParticipantQuizzes() {
    const quizzes = this.state.quizzes.filter(item => item.type === 'participante');
    return this.renderQuizzes(quizzes);
  }

  styleCells() {
    const columns = [
      {
        title: "Tipo",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Categorização",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Enunciado",
        width: { wch: 100 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Curiosidade",
        width: { wch: 100 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Tipo de Jogo",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Resposta",
        width: { wch: 100 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Correta",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      }
    ];

    let data = [];

    this.state.quizzes.forEach(item => {
      item.pt.answers.forEach(res => {
        data.push([
          { value: item.type == "instrutor" ? "Treinamento" : "Game" },
          { value: item.training },
          { value: item.pt.statement },
          { value: item.pt.curiosity ? item.pt.curiosity : " - " },
          { value: this.renameTypeGameInExcel(item.typeGame) },
          { value: res.answer },
          { value: res.correct ? "Sim" : "Não" }
        ]);
      });
    });

    return [
      {
        columns,
        data
      }
    ];
  }

  participantStyleCell() {
    const quizzesParticipant = this.state.quizzes.filter((quiz) => quiz.type === 'participante' && quiz.active === true);
    
    let data = [];
    let powerQuizCompleted;
    let totalPoints = 0;
    
    let columns = [
      {
        title: "Participante",
        width: { wch: 30  },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },

      {
        title: "Turma",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
    ]

    quizzesParticipant.forEach(() => {
      columns.push(
        {
          title: "Quiz",
          width: { wch: 15 },
          style: {
            font: { bold: true, color: { rgb: "ffffff" } },
            fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
          },                             
        },
        {
          title: "Realizado",
          width: { wch: 7 },
          style: {
            font: { bold: true, color: { rgb: "ffffff" } },
            fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
          },                             
        },
        {
          title: "Pontos",
          width: { wch: 5 },
          style: {
            font: { bold: true, color: { rgb: "ffffff" } },
            fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
          },                             
        },
      )
    })

    columns.push({
      title: "Total de Pontos",
      width: { wch: 12 },
      style: {
        font: { bold: true, color: { rgb: "ffffff" } },
        fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
      },                             
    })

    this.state.participants.forEach((participant) => {
      const dataSheet = [];
      
      dataSheet.push(
        { value: participant.name },
        { value: participant.class }
      )
      
      if (participant.gameCompleted.length) {
        powerQuizCompleted = participant.gameCompleted.map((gameCompleted) => {
          return this.state.powers.find((power) => power._id === gameCompleted);
        })
      }
      
      quizzesParticipant.forEach((quiz) => {
        dataSheet.push({
          value: quiz.training
        })
      
        if (!participant.gameCompleted.length) {
          dataSheet.push( 
            { value: 'Não' },
            { value: 0 }
          )
        } else {
          dataSheet.push(
            { value: powerQuizCompleted.some((powerQuiz) => powerQuiz.name === quiz.training) ? 'Sim' : 'Não' },
            { value: powerQuizCompleted.some((powerQuiz) => powerQuiz.name === quiz.training) ? 1 : 0 }
          ) 
        }
      })
      
      totalPoints = participant.gameCompleted.length;
      dataSheet.push(
        { value: totalPoints }
      )

      data.push(dataSheet)
    })

    return [
      {
        columns,
        data
      }
    ];
  }

  changeActive = async e => {
    const id = e.target.id;
    const status = e.target.checked;
    QuizService.changeActive(id, { status }).catch(() => {})
  };

  removeQuiz = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir um quiz, ele não aparecerá mais para os usuários e não poderá ser mais ser utilizado no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Este quiz ainda poderá ser restaurado</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await QuizService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }


  renameTypeGameInExcel(type) {
    switch (type) {
      case "fillGap":
        return "Preencher Lacuna";
      case "trueFalse":
        return "Verdadeiro ou Falso"
      default:
        return ' - '
    }
  }

  renderButtonExportExcel(data) {
    if (this.state.quizzes.length) {
      return (
        <ExcelFile
          element={
            <button className="button-full btn-export">
              <i className="fa fa-file-excel"></i> {data.title}
            </button>
          }
          filename={data.filename + new Date().getTime()}
        >
          <ExcelSheet dataSet={data.dataSet} name="Quizzes"></ExcelSheet>
        </ExcelFile>
      );
    }
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Quizzes</h1>
        </div>

        <div className="row content-header">
          <h3 className="list-title">Lista de Quizzes</h3>
          <div className="row">
            <a
              href="/quizzes/create"
              className="button-full btn-create m-right"
              onClick={(e) => navigateUsingAchor(e, this)}
            >
              <i className="fa fa-plus"></i> Criar quiz
            </a>
            {this.renderButtonExportExcel({
              title: 'exportar',
              filename: 'quizzes_',
              dataSet: this.styleCells()
            })}
            {this.renderButtonExportExcel({
              title: 'participantes',
              filename: 'participantes_quizzes',
              dataSet: this.participantStyleCell()
            })}
          </div>
        </div>
        
        <Loading status={this.state.loading}>
          
          <div className="row">
            <div className="col-md-12 quiz-list-type">
              Treinamento - Instrutor
            </div>
            <div className="col-md-12">
              <table className="table table-responsive table-hover table-striped">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Categorização</th>
                    <th>Enunciado</th>
                    <th>Alternativas</th>
                    <th>Ativo</th>
                    <th className="actions">Ações</th>
                  </tr>
                </thead>
                <tbody>{this.renderInstructorQuizzes()}</tbody>
              </table>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-12 quiz-list-type">
              Quizzes - Participante
            </div>
            <div className="col-md-12">
              <table className="table table-responsive table-hover table-striped">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Categorização</th>
                    <th>Enunciado</th>
                    <th>Alternativas</th>
                    <th>Ativo</th>
                    <th className="actions">Ações</th>
                  </tr>
                </thead>
                <tbody>{this.renderParticipantQuizzes()}</tbody>
              </table>
            </div>
          </div>
        </Loading>
        
      </div>
    );
  }
}

export default Quiz;
