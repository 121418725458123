import React, { Component } from "react";
import "./Login.scss";

import logo from "../../assets/images/logo/logo-horizontal.png";

import UserService from "../../services/auth.service";
import LocalStorage from "../../services/LocalStorage.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { navigate } from "../../services/navigation.service";
import { AuthContext } from "../../context/authContext";
const MySwal = withReactContent(Swal);

class Login extends Component {
  static contextType = AuthContext

  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
    };
  }

  componentDidMount = () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
  };

  changeLogin = async (e) => {
    await this.setState({ login: e.target.value });
  };
  changePassword = async (e) => {
    await this.setState({ password: e.target.value });
  };

  login = async () => {
    try {
      let user = {
        login: this.state.login,
        password: this.state.password,
      };

      let res = await UserService.login(user);
      if (res.success) {
        this.context.setContext({
          user: res.user,
          token: res.token,
          tokenExp: res.expirationDate
        })
      }
    } catch (err) {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>Digitar novamente</span>,
        icon: "error",
        title: <p>Usuário ou senha inválida.</p>,
      });
    }
  };

  render() {
    return (
      <div className="login">
        <div className="login-content">
          <img src={logo} alt="Logo Fazendo Acontecer" />
          <h2 className="login-title">PODERES EMPREENDEDORES</h2>

          <div className="login-form">
            <div className="form-group">
              <label className="login-label" htmlFor="">
                usuário
              </label>
              <input
                className="login-input"
                type="text"
                placeholder="digite seu usuário..."
                onChange={this.changeLogin.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="login-label" htmlFor="">
                senha
              </label>
              <input
                className="login-input"
                type="password"
                placeholder="digite sua senha..."
                onChange={this.changePassword.bind(this)}
              />
            </div>

            <button className="login-button" onClick={() => this.login()}>
              entrar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
