import "./Participant.scss"

import React, { Component } from 'react';
import moment from 'moment'
import 'moment/locale/pt-br'

import countries from '../../shared/helpers/countries';
import states from '../../shared/helpers/states';
import ParticipanteService from '../../services/participant.service';
import PowerService from '../../services/power.service';
import MissionService from '../../services/mission.service';
import SecurityService from '../../services/security.service';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import ReactExport from "react-data-export";
import DashboardService from '../../services/dashboard.service';
import WorkshopService from '../../services/workshop.service';
import orderByWorkshop from '../../shared/utils/orderByWorkshop';
import Loading from '../Loading/Loading';
import { navigate } from '../../services/navigation.service';
import { AuthContext } from '../../context/authContext';
import PaginationWithSearch from "../PaginationWithSearch/PaginationWithSearch";
import ParticipantItem from "./ParticipantItem";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const MySwal = withReactContent(Swal);

moment.locale('pt-BR');

class Participant extends Component {
  static contextType = AuthContext

  constructor() {
    super()
    this.state = {
      participants: [],
      date: [],
      powers: [],
      missions: [],
      workshops: [],
      report: [],
      name: '',
      born: '',
      power: '',
      mission: '',
      institution: '',
      country: '',
      states: '',
      loading: true
    }
    this.data = []
    this.participantsIds = []
  }


  showAlert(buttonText, icon, title) {
    MySwal.fire({
      confirmButtonColor: '#87BF40',
      confirmButtonText: <span>{buttonText}</span>,
      icon: icon,
      title: <p>{title}</p>
    })
  }

  componentDidMount = async () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')

    let participants = await ParticipanteService.getAll();
    const powers = await PowerService.getAll();
    let missions = await MissionService.getAll();
    let workshops = await WorkshopService.getAll();

    participants = participants.map(participant => {
      let powersParticipant = []
      let missionParticipant = []
      participant.powers.length && participant.powers.forEach(power => {
        let powerAux = powers.filter(p => p._id === power.powerId)[0]
        if (powerAux) {
          powersParticipant.push(powerAux.pt.name)
        }
      })
      participant.missions.length && participant.missions.forEach(mission => {
        let missionAux = missions.filter(m => m._id === mission.missionId)[0]
        if (missionAux) {
          missionParticipant.push(missionAux.pt.name)
        }
      })

      participant.powersParticipant = powersParticipant;
      participant.missionParticipant = missionParticipant;
      return participant

    })

    participants.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })

    await this.setState({
      participants: participants,
      powers: powers,
      missions: missions,
      workshops,
      loading: false
    })

  }

  handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    await this.setState({
      [name]: value
    });
  }


  styleCells() {
    const columns = [
      {
        title: "Nome",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Nascimento",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Idade",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Gênero",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Turma",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "IFAs",
        width: { wch: 10 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poderes 1",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poderes 2",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poderes 3",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poderes 4",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poderes 5",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missões 1",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missões 2",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missões 3",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missões 4",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missões 5",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Data cadastro participante",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
    ];

    let data = this.state.participants.map(item => {
      return [
        { value: item.name ? item.name : " - " },
        { value: item.born ? moment(item.born).format("DD/MM/YYYY") : " - " },
        { value: item.born ? moment.duration(moment().diff(item.born)).get('years') : '-' },
        { value: item.gender ? this.formatGender(item.gender) : " - " },
        { value: item.class ? item.class : " - " },
        { value: item.IFAs ? item.IFAs : 0 },
        { value: item.powersParticipant.length >= 1 ? item.powersParticipant[0] : " - " },
        { value: item.powersParticipant.length >= 2 ? item.powersParticipant[1] : " - " },
        { value: item.powersParticipant.length >= 3 ? item.powersParticipant[2] : " - " },
        { value: item.powersParticipant.length >= 4 ? item.powersParticipant[3] : " - " },
        { value: item.powersParticipant.length >= 5 ? item.powersParticipant[4] : " - " },
        { value: item.missionParticipant.length >= 1 ? item.missionParticipant[0] : " - " },
        { value: item.missionParticipant.length >= 2 ? item.missionParticipant[1] : " - " },
        { value: item.missionParticipant.length >= 3 ? item.missionParticipant[2] : " - " },
        { value: item.missionParticipant.length >= 4 ? item.missionParticipant[3] : " - " },
        { value: item.missionParticipant.length >= 5 ? item.missionParticipant[4] : " - " },
        { value: item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : " - " },
      ];
    });

    return [
      {
        columns,
        data
      }
    ];
  }

  reportStyleCell() {

    const columns = [
      {
        title: "Nome",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Nascimento",
        width: { wch: 12 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Idade",
        width: { wch: 6 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Gênero",
        width: { wch: 16 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Oficina",
        width: { wch: 25 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poder",
        width: { wch: 25 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missão",
        width: { wch: 25 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Missão realizada",
        width: { wch: 16 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Instituição",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Pais",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Estado",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Turma",
        width: { wch: 8 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Instrutor",
        width: { wch: 25 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Data de cadastro",
        width: { wch: 15 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
    ];

    let report = this.state.report.sort((r1, r2) => {
      return r1.code > r2.code ? 1 : -1;
    })

    report.forEach(item => this.getParticipantsData(report, item.participant._id));

    //Ordenar por nome
    this.data.sort((d1, d2) => {
      if (d1[11].value === d2[11].value)
        return d1[0].value > d2[0].value ? 1 : -1;

      return 0;
    })

    //Ordenar por oficina
    this.data.sort((d1, d2) => {
      if (d1[0].value === d2[0].value && d1[11].value === d2[11].value)
        return orderByWorkshop(d1[4].value, d2[4].value);

      return 0;
    })

    return [
      {
        columns,
        data: this.data
      }
    ];

  }

  getParticipantsData(list, id) {
    let idAlreadyExists = this.participantsIds.some(item => item === id);

    if (!idAlreadyExists) {
      this.participantsIds.push(id);
      let filtered = list.filter(item => item.participant._id === id);
      this.buildData(filtered);
    };

  }

  buildData(list) {
    list.forEach(item => {
      let ageAuxiliar = moment.min([moment(), moment(item.dateTime)]);
      this.data.push(Array.of(
        { value: item.participant.name ? item.participant.name : " - " },
        { value: item.participant.born ? moment(item.participant.born).format("DD/MM/YYYY") : " - " },
        { value: item.participant.born ? moment.duration(ageAuxiliar.diff(item.participant.born)).get('years') : " - " },
        { value: item.participant.gender ? this.formatGender(item.participant.gender) : " - " },
        { value: this.state.workshops.find(wk => wk._id === item.workshop).pt.name },
        { value: 'power' in item ? this.state.powers.find(p => p._id === item.power).pt.name : " - " },
        { value: 'mission' in item ? this.state.missions.find(m => m._id === item.mission).pt.name : " - " },
        { value: 'star' in item ? "sim" : "não" },
        { value: item.instructor ? item.instructor.institution.name : " - " },
        { value: item.instructor ? item.instructor.address.country : " - " },
        { value: item.instructor ? item.instructor.address.state : " - " },
        { value: item.code },
        { value: item.instructor ? item.instructor.name : " - " },
        { value: item.dateTime ? moment(item.dateTime).format("DD/MM/YYYY") : " - " }
      ))
    })

  }


  buttonGenerateReportBlockAndUnblock(block) {
    if (block) {
      this.btnGenerateReport.innerHTML = "Carregando..."
      this.btnGenerateReport.setAttribute("disabled", "true");
    } else {
      this.btnGenerateReport.innerHTML = "gerar relatorio"
      this.btnGenerateReport.removeAttribute("disabled");
    }
  }

  validateReport = async () => {

    this.buttonGenerateReportBlockAndUnblock(true);
    // if (this.state.born === '' && this.state.power === '' && this.state.mission === '' && this.state.institution === '' && this.state.country === '' && this.state.states === '') {
    //   this.buttonGenerateReportBlockAndUnblock(false);
    //   return this.showAlert('OK', 'error', 'Para gerar o relatorio, pelo menos 1 campo deve se preenchido');
    // }

    await this.setState({
      report: []
    });

    const { metadata, result } = await DashboardService.attendance(
      { age: this.state.born, power: this.state.power, mission: this.state.mission, institution: this.state.institution, country: this.state.country, state: this.state.states });

    if (!metadata.count) {
      this.buttonGenerateReportBlockAndUnblock(false);
      return this.showAlert('OK', 'error', 'Não foi encontrado dados para essa busca');
    }

    await this.setState({
      report: result
    });

    this.buttonGenerateReportBlockAndUnblock(false);
  }

  renderButtonExportExcel() {
    if (this.state.participants) {
      return (
        <ExcelFile
          element={
            <button className="button-full btn-export">
              <i className="fa fa-file-excel"></i> exportar
            </button>
          }
          filename={"participante"}
        >
          <ExcelSheet dataSet={this.styleCells()} name="Participantes"></ExcelSheet>
        </ExcelFile>
      );
    }
  }

  removeParticipant = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir um participante, ele não aparecerá mais para os usuários e não poderá ser mais ser utilizado no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Este participante ainda poderá ser restaurado</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await ParticipanteService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }

  formatGender(gender) {
    const genders = ['male', 'female', 'other']
    const display = ['Masculino', 'Feminino', 'Outro']

    const index = genders.indexOf(gender);
    return index === -1 ? '' : display[index];
  }

  renderReportExcelButton() {
    if (this.state.report.length) {
      return (
        <ExcelFile
          element={
            <button className="button-full btn-create modal-btn">
              <i className="fa fa-file-excel"></i> download
            </button>
          }
          filename={"relatório participante"}
        >
          <ExcelSheet dataSet={this.reportStyleCell()} name="relatorio-participantes-completo"></ExcelSheet>
        </ExcelFile>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-card">
          <div className="row page-title">
            <h1>Participantes</h1>
          </div>
          <div className="row content-header">
            <h3 className="list-title">Lista de Participante</h3>
            <div className="row">
              {this.renderButtonExportExcel()}

              <a className="button-full btn-export" data-toggle="modal" data-target="#exampleModal">
                <i className="fa fa-file-excel"></i> relatório geral
              </a>
            </div>
          </div>

          <Loading status={this.state.loading}>
            <div className="row">
              <div className="col-md-12">
                <PaginationWithSearch
                  data={this.state.participants}
                  itemsPerPage={50}>
                    <PaginationWithSearch.Searchbox 
                    placeholder="Pesquisar por nome ou turma"
                  />
                  <PaginationWithSearch.Buttons />
                <table className="table table-responsive table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Data de nascimento</th>
                      <th>Apelido</th>
                      <th>Gênero</th>
                      <th>Cor favorita</th>
                      <th>Animal favorito</th>
                      <th>Turma</th>
                      <th>IFAs</th>
                      <th>Poderes</th>
                      <th>Missões</th>
                      {!SecurityService.isMaster() ? <></> :
                        <th className="actions">Ação</th>
                      }
                    </tr>
                  </thead>

                  <tbody>
                      <PaginationWithSearch.Data
                        render={(item) => 
                          <ParticipantItem
                            key={item?._id}
                            item={item}
                            removeParticipant={() => this.removeParticipant(item._id)}
                            gender={this.formatGender(item.gender)}
                          />
                        }
                      />
                  </tbody>
                </table>
                </PaginationWithSearch>
              </div>
            </div>
          </Loading>

        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="list-title">Relatório completo do Participante</h3>
                <button className="power-button remove closed" data-dismiss="modal" aria-label="Close" title="Remover"><i className="fa fa-times"></i></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Idade:</label>
                      <input type="number" name="born" className="form-control" onChange={(e) => { this.setState({ born: e.target.value }) }} required defaultValue={this.state.born} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Poder:</label>
                      <select name="power" className="form-control"
                        onChange={this.handleInputChange} value={this.state.power}>
                        <option value="">Selecione um poder</option>
                        {
                          this.state.powers.map(item => {
                            return (
                              <option key={item._id} value={item._id}>{item.pt.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Missões:</label>
                      <select name="mission" className="form-control"
                        onChange={this.handleInputChange} value={this.state.mission}>
                        <option value="">Selecione uma missão</option>
                        {
                          this.state.missions.map(item => {
                            return (
                              <option key={item._id} value={item._id}>{item.pt.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Instituição:</label>
                      <input type="text" name="institution" className="form-control" onChange={this.handleInputChange} value={this.state.institution} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>País:</label>
                      <select name="country" className="form-control"
                        onChange={this.handleInputChange} defaultValue={this.state.country}>
                        <option value="">Selecione o país</option>
                        {
                          countries.map((country, index) =>
                            <option key={index} value={country.pais}>{country.pais}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Estado:</label>
                      <select name="states" className="form-control"
                        onChange={this.handleInputChange} defaultValue={this.state.states}>
                        <option value="">Selecione o estado</option>
                        {
                          states.map((state, index) =>
                            <option key={index} value={state}>{state}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <button className="button-full btn-create modal-btn" ref={el => this.btnGenerateReport = el}
                    onClick={this.validateReport.bind(this)}> gerar relatorio</button>
                </div>
                <div className="col-md-12">
                  {this.renderReportExcelButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Participant;