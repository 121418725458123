import React, { Component } from "react";
import Toggle from "react-toggle";
import "./Training.scss";
import ReactExport from "react-data-export";

import TrainingService from "../../services/training.service";
import SecurityService from "../../services/security.service";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AuthContext } from "../../context/authContext";
import { navigate, navigateUsingAchor } from "../../services/navigation.service";
const MySwal = withReactContent(Swal)

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Training extends Component {
  static contextType = AuthContext

  constructor() {
    super();
    this.state = {
      trainings: []
    };
  }

  componentWillMount = async () => {
    let trainings = await TrainingService.getAll();

    await this.setState({
      trainings: trainings
    });
  };

  componentDidMount = () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
};

  //   styleCells() {
  //     const columns = [
  //       {
  //         title: "Nome",
  //         width: { wch: 20 },
  //         style: {
  //           font: { bold: true, color: { rgb: "ffffff" } },
  //           fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
  //         }
  //       },
  //       {
  //         title: "Poder",
  //         width: { wch: 30 },
  //         style: {
  //           font: { bold: true, color: { rgb: "ffffff" } },
  //           fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
  //         }
  //       },
  //       {
  //         title: "Contexto",
  //         width: { wch: 100 },
  //         style: {
  //           font: { bold: true, color: { rgb: "ffffff" } },
  //           fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
  //         }
  //       },
  //       {
  //         title: "O que fazer",
  //         width: { wch: 200 },
  //         style: {
  //           font: { bold: true, color: { rgb: "ffffff" } },
  //           fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
  //         }
  //       },
  //       {
  //         title: "Resultado",
  //         width: { wch: 100 },
  //         style: {
  //           font: { bold: true, color: { rgb: "ffffff" } },
  //           fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
  //         }
  //       },
  //       {
  //         title: "Dica",
  //         width: { wch: 200 },
  //         style: {
  //           font: { bold: true, color: { rgb: "ffffff" } },
  //           fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
  //         }
  //       }
  //     ];
  //     let data = this.state.missions.map(item => {
  //       return [
  //         { value: item.pt ? item.pt.name : '-' },
  //         { value: item.powerName ? item.powerName : '-' },
  //         { value: item.pt ? item.pt.context : '-' },
  //         { value: item.pt ? item.pt.toDo : '-' },
  //         { value: item.pt ? item.pt.result : '-' },
  //         { value: item.pt ? item.pt.tip : '-' }
  //       ];
  //     });

  //     return [
  //       {
  //         columns,
  //         data
  //       }
  //     ];
  //   }

  changeActive = async e => {
    const id = e.target.id;
    const status = e.target.checked;
    TrainingService.changeActive(id, { status }).catch(() => {})
  };

  removeTraining = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir um treinamento, ele não aparecerá mais para os usuários e não poderá ser mais ser utilizado no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Este treinamento ainda poderá ser restaurado</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await TrainingService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }

  renderTrainings() {
    let trainings = [];
    trainings = this.state.trainings.map((item, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.pt.name}</td>
          <td>
            <Toggle
              id={item._id}
              defaultChecked={item.active}
              onChange={this.changeActive.bind(this)}
            />
          </td>
          <td>
            <a 
              className="btn-edit" 
              href={`/trainings/${item._id}`}
              onClick={(e) => navigateUsingAchor(e, this)}
            >
              editar
            </a>
            {/* {SecurityService.isMaster() ?
              <a
                className="ml-3 btn-remove"
                href="javascript:void(0)"
                onClick={() => this.removeTraining(item._id)}
              >
                remover
              </a> : <></>
            } */}
          </td>
        </tr>
      );
    });
    return trainings;
  }

  //   renderButtonExportExcel() {
  //     if (this.state.missions.length) {
  //       return (
  //         <ExcelFile
  //           element={
  //             <button className="button-full btn-export">
  //               <i className="fa fa-file-excel"></i> exportar
  //             </button>
  //           }
  //           filename={"missoes_" + new Date().getTime()}
  //         >
  //           <ExcelSheet dataSet={this.styleCells()} name="Missoes"></ExcelSheet>
  //         </ExcelFile>
  //       );
  //     }
  //   }

  render() {

    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Treinamentos</h1>
        </div>

        <div className="row content-header">
          <h3 className="list-title">Lista de treinamentos</h3>
          <div className="row">
            <a 
              href="/trainings/create" 
              className="button-full btn-create m-right"
              onClick={(e) => navigateUsingAchor(e, this)}
            >
              <i className="fa fa-plus"></i> criar treinamentos
            </a>
            {/* {this.renderButtonExportExcel()} */}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-responsive table-hover table-striped">
              <thead>
                <tr>
                  <th>nº</th>
                  <th>Nome</th>
                  <th>Ativo</th>
                  <th className="actions">Ações</th>
                </tr>
              </thead>
              <tbody>{this.renderTrainings()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Training;
