import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import handleEditLicenseExpireDate from './handleEditLicenseExpireDate';

const MySwal = withReactContent(Swal);

const popupChooseNewExpireDate = (instructor, licenseExpireIn, setInstructors) => {
  let newLicenseExpirationDate;

    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: (
        <span style={{ color: "#FFC107" }}>Editar expiração da licença</span>
      ),
      html: (
        <>
          <p style={{ color: "#000" }}>
            Selecione uma nova data de expiração para a licença:
          </p>
          <input
            type="date"
            className="form-control wt-9rem"
            defaultValue={licenseExpireIn}
            onChange={(e) => newLicenseExpirationDate = e.target.value}
          />
        </>
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        handleEditLicenseExpireDate(
          instructor,
          newLicenseExpirationDate,
          licenseExpireIn,
          setInstructors
        );
      }
    });
  };

export default popupChooseNewExpireDate;