import React, { Component } from 'react';
import './Instructor-create.scss';

import countries from '../../../shared/helpers/countries';
import CepService from '../../../services/cep.service';
import InstructorService from '../../../services/instructor.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class InstructorNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailConfirm: '',
      password: '',
      passwordConfirm: '',
      username: '',
      name: '',
      born: '',
      cpf: '',
      rg: '',
      nationality: 'brasileiro',
      country: '',
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      state: '',
      city: '',
      neighborhood: '',
      cellphone: '',
      phone: '',
      nameInstitution: '',
      countryInstitution: '',
      zipCodeInstitution: '',
      streetInstitution: '',
      numberInstitution: '',
      complementInstitution: '',
      stateInstitution: '',
      cityInstitution: '',
      neighborhoodInstitution: '',
      website: '',
      phoneInstitution: '',
      isTeacher: 'true',
      receiveMessagesIfa: 'true',
      courses: '',
    }
  }

  handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    await this.setState({
      [name]: value
    });
  }

  verifyLocalization = async (e) => {
    const zipCodeType = e.target.name;
    if ((zipCodeType == 'zipCode' && (this.state.country === 'Brasil' || this.state.country === '')) ||
      (zipCodeType == 'zipCodeInstitution' && (this.state.countryInstitution === 'Brasil' || this.state.countryInstitution === ''))) {
      let cep = e.target.value;
      cep = cep.replace(/\-/g, "");
      if (cep.length == 8) {
        const localization = await CepService.getLocalization(cep);
        if (!localization.erro) {
          if (zipCodeType == 'zipCode') {
            await this.setState({
              street: localization.logradouro,
              neighborhood: localization.bairro,
              city: localization.localidade,
              state: localization.uf
            })
          } else {
            await this.setState({
              streetInstitution: localization.logradouro,
              neighborhoodInstitution: localization.bairro,
              cityInstitution: localization.localidade,
              stateInstitution: localization.uf
            })
          }
        }
      }
    }
  };

  showAlert(buttonText, icon, title) {
    MySwal.fire({
      confirmButtonColor: '#87BF40',
      confirmButtonText: <span>{buttonText}</span>,
      icon: icon,
      title: <p>{title}</p>
    })
  }

  goBack = () => {
    this.props.history.push('/instructor')
  }

  create = async () => {
    if (this.validEmptyInput()) {
      this.showAlert('OK', 'error', 'Há campos obrigatórios que não foram preenchidos')
    } else {
      if (this.state.email != this.state.emailConfirm) {
        this.showAlert('Digitar novamente', 'error', 'A confirmação de e-mail não confere')
      } else if (this.state.password != this.state.passwordConfirm) {
        this.showAlert('Digitar novamente', 'error', 'A confirmação de senha não confere')
      } else {
        const instrutor = {
          name: this.state.name,
          cpf: this.state.cpf,
          rg: this.state.rg,
          born: this.state.born,
          nationality: this.state.nationality,
          cellphone: this.state.cellphone,
          address: {
            street: this.state.street,
            number: this.state.number,
            complement: this.state.complement,
            neighborhood: this.state.neighborhood,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipCode,
            country: this.state.country
          },
          institution: {
            name: this.state.nameInstitution,
            website: this.state.website,
            phone: this.state.phoneInstitution,
            address: {
              street: this.state.streetInstitution,
              number: this.state.numberInstitution,
              complement: this.state.complementInstitution,
              neighborhood: this.state.neighborhoodInstitution,
              city: this.state.cityInstitution,
              state: this.state.stateInstitution,
              zipcode: this.state.zipCodeInstitution,
              country: this.state.countryInstitution,
            },
          },
          isTeacher: this.state.isTeacher,
          courses: this.state.courses,
          receiveMessagesIfa: this.state.receiveMessagesIfa,
          username: this.state.username,
          email: this.state.email,
          password: this.state.password
        }

        try {
          let res = await InstructorService.create(instrutor);
          if (res.instructor._id) {
            MySwal.fire({
              confirmButtonColor: '#87BF40',
              confirmButtonText: <span>OK</span>,
              icon: 'success',
              title: <p>Instrutor cadastrado com sucesso</p>
            })
            this.props.history.push('/instructor')
          }
        } catch (err) {
          console.log(err)
          MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>Tentar novamente</span>,
            icon: 'error',
            title: <p>Erro ao cadastrar consultor.</p>
          })
        }
      }
    }

  }

  validEmptyInput = () => {
      if(
          this.state.email == '' || this.state.emailConfirm == '' ||
          this.state.password == '' || this.state.passwordConfirm == '' ||
          this.state.username == '' || this.state.name == '' ||
          this.state.born == '' || this.state.country == '' || this.state.zipCode == '' ||
          this.state.street == '' || this.state.number == '' ||
          this.state.state == '' || this.state.city == '' ||
          this.state.neighborhood == '' || this.state.cellphone == '' ||
          this.state.nameInstitution == '' || this.state.countryInstitution == '' || 
          this.state.zipCodeInstitution == '' || this.state.streetInstitution == '' || 
          this.state.numberInstitution == '' || this.state.stateInstitution == '' || 
          this.state.cityInstitution == '' || this.state.neighborhoodInstitution == ''
      )
          return true;
      return false;
  }
  
  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Novo instrutor</h1>
        </div>

        <div className="row">
          <div className="col-md-12">
            <form>
              <div>
                <h3 className="form-title">Informações de acesso</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>E-mail:<span className="highlighter">*</span></label>
                      <input type="text" name="email" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.email} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Confirmação de e-mail:<span className="highlighter">*</span></label>
                      <input type="text" name="emailConfirm" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.emailConfirm} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Nome de usuário:<span className="highlighter">*</span></label>
                      <input type="text" name="username" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.username} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Senha:<span className="highlighter">*</span></label>
                      <input type="password" name="password" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.password} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Confirmação de senha:<span className="highlighter">*</span></label>
                      <input type="password" name="passwordConfirm" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.passwordConfirm} />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h3 className="form-title">Dados cadastrais</h3>
                <div className="row">
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>Nome completo:<span className="highlighter">*</span></label>
                      <input type="text" name="name" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.name} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Data de nascimento:<span className="highlighter">*</span></label>
                      <input type="date" className="form-control" name="born"
                        onChange={this.handleInputChange} value={this.state.born} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{this.state.nationality == 'brasileiro' ? 'CPF:' : 'Passaporte:'}</label>
                      <input type="text" className="form-control" name="cpf"
                        onChange={this.handleInputChange} value={this.state.cpf} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {
                      this.state.nationality == 'brasileiro' ?
                        <div className="form-group">
                          <label>RG:</label>
                          <input type="text" className="form-control" name="rg" onChange={this.handleInputChange} value={this.state.rg} />
                        </div> :
                        <div></div>
                    }
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nacionalidade:<span className="highlighter">* </span></label>
                      <input type="radio" className="ml-3 mr-1"
                        name="nationality"
                        onChange={this.handleInputChange}
                        value="brasileiro"
                        defaultChecked={true} />
                      <label>Brasileiro</label>
                      <input type="radio" className="ml-3 mr-1"
                        name="nationality"
                        onChange={this.handleInputChange}
                        value="outra" />
                      <label>Outra</label>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h3 className="form-title">Endereço e contato</h3>
                <div className="row">
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>País:<span className="highlighter">*</span></label>
                      <select name="country" className="form-control"
                        onChange={this.handleInputChange} value={this.state.country}>
                        <option value="">Selecione o país</option>
                        {
                          countries.map((country, index) =>
                            <option key={index} value={country.pais}>{country.pais}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>CEP:<span className="highlighter">*</span></label>
                      <input type="text" name="zipCode" className="form-control" required
                        onChange={this.handleInputChange}
                        value={this.state.zipCode}
                        onBlur={this.verifyLocalization.bind(this)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Endereço:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="street" required
                        onChange={this.handleInputChange} value={this.state.street} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Número:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="number" required
                        onChange={this.handleInputChange} value={this.state.number} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Complemento:</label>
                      <input type="text" className="form-control" name="complement"
                        onChange={this.handleInputChange} value={this.state.complement} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Estado:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="state" required
                        onChange={this.handleInputChange} value={this.state.state} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cidade:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="city" required
                        onChange={this.handleInputChange} value={this.state.city} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Bairro:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="neighborhood" required
                        onChange={this.handleInputChange} value={this.state.neighborhood} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>WhatsApp / Celular:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="cellphone" required
                        onChange={this.handleInputChange} value={this.state.cellphone} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Telefone fixo:</label>
                      <input type="text" className="form-control" name="phone"
                        onChange={this.handleInputChange} value={this.state.phone} />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h3 className="form-title">Dados da instituição</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nome da instituição:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="nameInstitution" required
                        onChange={this.handleInputChange} value={this.state.nameInstitution} />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>País:<span className="highlighter">*</span></label>
                      <select name="countryInstitution" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.countryInstitution}>
                        <option value="">Selecione o país</option>
                        {
                          countries.map((country, index) =>
                            <option key={`institution-${index}`} value={country.pais}>{country.pais}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>CEP:<span className="highlighter">*</span></label>
                      <input type="text" name="zipCodeInstitution" className="form-control" required
                        onChange={this.handleInputChange}
                        value={this.state.zipCodeInstitution}
                        onBlur={this.verifyLocalization.bind(this)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Endereço:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="streetInstitution" required
                        onChange={this.handleInputChange} value={this.state.streetInstitution} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Número:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="numberInstitution" required
                        onChange={this.handleInputChange} value={this.state.numberInstitution} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Complemento:</label>
                      <input type="text" className="form-control" name="complementInstitution"
                        onChange={this.handleInputChange} value={this.state.complementInstitution} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Estado:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="stateInstitution" required
                        onChange={this.handleInputChange} value={this.state.stateInstitution} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cidade:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="cityInstitution" required
                        onChange={this.handleInputChange} value={this.state.cityInstitution} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Bairro:<span className="highlighter">*</span></label>
                      <input type="text" className="form-control" name="neighborhoodInstitution" required
                        onChange={this.handleInputChange} value={this.state.neighborhoodInstitution} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Site da instituição:</label>
                      <input type="text" className="form-control" name="website"
                        onChange={this.handleInputChange} value={this.state.website} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Telefone:</label>
                      <input type="text" className="form-control" name="phoneInstitution"
                        onChange={this.handleInputChange} value={this.state.phoneInstitution} />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h3 className="form-title">Informações adicionais</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>É professor?:<span className="highlighter">* </span></label>
                      <input type="radio" className="ml-3 mr-1" name="isTeacher" value="true"
                        onChange={this.handleInputChange}
                        defaultChecked={true} />
                      <label>Sim</label>
                      <input type="radio" className="ml-3 mr-1" name="isTeacher" value="false"
                        onChange={this.handleInputChange} />
                      <label>Não</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Deseja receber e-mail IFA?:<span className="highlighter">*</span></label>
                      <input type="radio" className="ml-3 mr-1" name="receiveMessagesIfa" value="true"
                        onChange={this.handleInputChange}
                        defaultChecked={true} />
                      <label>Sim</label>
                      <input type="radio" className="ml-3 mr-1" name="receiveMessagesIfa" value="false"
                        onChange={this.handleInputChange} />
                      <label>Não</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Cursos que leciona:</label>
                      <input type="text" name="courses" className="form-control"
                        onChange={this.handleInputChange} value={this.state.courses} />
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row row-center">
                <div className="col-8">
                  <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                </div>
                <div className="col-md-4 text-right">
                <button
                    className="button-full btn-goback"
                    onClick={() => this.goBack()}
                    type="button"
                  >
                    Voltar
                  </button>
                  <button
                    className="button-full btn-save"
                    onClick={() => this.create()}
                    type="button"
                  >
                    <i className="fa fa-check"></i> Cadastrar
                  </button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default InstructorNew;