import React, { Component } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import "./Classes.scss";
import { EditWorkshops } from "./EditWorkshops/EditWorkshops";
import { EditParticipants } from "./EditParticipants/EditParticipants";
import { EditPresenceListStudents } from "./EditPresenceListStudents/EditPresenceListStudents";
import { AttributePresenceListStudents } from "./AttributePresenceList/AttributePresenceListStudents";
import { EditPowersStudents } from "./EditPowersStudents/EditPowersStudents";
import { EditMissionsStudents } from "./EditMissionsStudents/EditMissionsStudents";
import { EditStarsStudents } from "./EditStarsStudents/EditStarsStudents";

import Pagination from "react-js-pagination";

import ReportsModal from "../ReportsModal/ReportsModal";
import countries from "../../../shared/helpers/countries";
import states from "../../../shared/helpers/states";
import ClassService from "../../../services/class.service";
import DashboardService from "../../../services/dashboard.service";
import InstructorService from "../../../services/instructor.service";
import WorkshopService from "../../../services/workshop.service";
import SecurityService from "../../../services/security.service";
import PowersService from "../../../services/power.service";
import ParticipantService from "../../../services/participant.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../Loading/Loading";
import { navigate } from "../../../services/navigation.service";
import { AuthContext } from "../../../context/authContext";

const MySwal = withReactContent(Swal);

moment.locale("pt-BR");

class Dashboard extends Component {
  static contextType = AuthContext
  component = this;

  constructor() {
    super();

    this.state = {
      classes: [],
      classesToExport: [],
      workshops: [],
      instructors: [],
      dateToEdit: [],
      worskshopCodeDate: [],
      powers: [],
      loading: true,

      searchInstructor: "",
      searchDateStart: "2021-01-01",
      searchDateEnd: "",
      searchClassType: "",
      searchState: "",
      searchCountry: "",
      searchNotStarted: "",

      skip: 0,
      limit: 50,
      totalItens: 0,
      itemsPerPage: [20, 50, 100],
      currentPage: 1,

      classesFilter: "",
      userData: [],
      firstStateUserData: [],
      resultPopup: false,

      savePresence: null,
      classInfo: null,
    };
  }

  componentWillMount = async () => {
    await this.search(1);

    const instructors = await InstructorService.getAllNames();
    const workshops = await WorkshopService.getAll();
    const powers = await PowersService.getAll()

    this.setState({
      instructors,
      workshops,
      powers
    });
  };

  backToEditWorkshopPopup = () => {
    this.editWorkshopPopup(this.state.classInfo);
  }

  handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    await this.setState({
      [name]: value,
    });
  };

  handleInputChangeEditDate = (e, index, codeWorkshop) => {
    const value = e.target.value;
    const newValues = this.state.dateToEdit;
    const newValuesWorkshop = this.state.worskshopCodeDate;

    newValuesWorkshop[index] = codeWorkshop;
    newValues[index] = value;
    this.setState({
      dateToEdit: newValues,
      worskshopCodeDate: newValuesWorkshop,
    });
  };

  async createWorkshopsArray() {
    let res = [];
    let data;
    let i = 0;
    this.state.dateToEdit.map(async (item) => {
      data = {
        workshop: this.state.worskshopCodeDate[i],
        newDateWorkShop: item,
      };
      i++;
      res.push(data);
    });
    return res;
  }

  search = async (page, initialLoading = true) => {
    this.setState({
      currentPage: page ? page : 1,
      skip: (page - 1) * this.state.limit,
      loading: initialLoading,
    });

    const query = {
      ...this.filter(),
      skip: this.state.limit * (page - 1),
      limit: this.state.limit,
    };

    const classes = await DashboardService.searchClasses(query);

    this.setState({
      classes: classes.result,
      totalItens: classes.metadata.count,
      loading: false,
    });
  };

  removeClass = (code) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "warning",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: "#FFC107" }}>Mover para a lixeira?</span>,
      html: (
        <p style={{ color: "#000" }}>
          Ao excluir uma turma, ela não aparecerá mais para os usuários e não
          poderá ser mais ser utilizada no aplicativo.
          <br />
          <br />
          <span style={{ color: "#FFC107" }}>
            Esta turma ainda poderá ser restaurada
          </span>
        </p>
      ),
    })
      .then(async (result) => {
        if (result.value) {
          let res = await DashboardService.removeClass(code);
          if (res._id) {
            // window.location.reload();
            await this.search(this.state.currentPage, false);

            const navigator = navigate.bind(this.component);
            navigator({ goBack: true });
          }
        }
      })
      .catch((err) => {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível excluir</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        });
      });
  };

  editClass = async (id) => {
    let res = await DashboardService.getDatesToEdit(id);
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: "#FFC107" }}>Editar Datas</span>,
      html: this.getListDateEdit(res),
    })
      .then(async (result) => {
        if (result.value) {
          let data = {
            idClass: id,
            workshops: await this.createWorkshopsArray(),
          };
          let res = await DashboardService.putDatesToEdit(data);
          if (res._id) {
            const navigator = navigate.bind(this.component);
            navigator({ goBack: true });
          }
        }
      })
      .catch((err) => {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        });
      });
  };

  handleEditWorkshops(workshop, ref, item) {
    const reference = ref.current.className;

    if (reference === "editPresenceList")
      this.editPresenceListStudentsPopup(workshop);
    if (reference === "attributePresenceList")
      this.attributePresenceListStudentsPopup(workshop);
    if (reference === "powers") this.editPowersStudentsPopup(workshop, item);
    if (reference === "missions") this.editMissionsStudentsPopup(workshop);
    if (reference === "stars") this.editStarsStudentsPopup(workshop);
  }

  async savePresence(workshopId, participants) {
    const presenceList = {
      workshop: workshopId,
      participants: participants,
    };
    const classCode = JSON.parse(
      window.localStorage.getItem("currentClass")
    ).code;
    await ClassService.fillPresence(classCode, presenceList);
  }

  changePresenceList = async (workshop) => {
    try {
      await this.savePresence(workshop.workshop, workshop.presenceList);
    } catch (error) {
      console.error(error);
    }
  };

  seedUserData = (response, generalData) => {
    const presentParticipants = response.filter(participantResponse =>
      generalData.some(participantData => participantData.workshops.participant._id === participantResponse.participantId && participantData.workshops.presenceList.presence)
    );  

    this.setState({ userData: presentParticipants });
    this.setState({ firstStateUserData: presentParticipants });
  }

  compareUsersData = (data, originalData, editType) => {
    if(data.participantId === originalData.participantId) {
      if(editType === "missions")
        return data.missionId !== originalData.missionId
      if(editType === "powers")
        return data.powerId !== originalData.powerId
    }

    return true
  }

  editPresenceListStudentsPopup(workshop) {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: false,
      title: (
        <span style={{ color: "#FFC107" }}><i class="fa fa-arrow-left fa-xs" onClick={() => this.backToEditWorkshopPopup()} aria-hidden="true"></i> Editar Presença</span>
      ),
      html: (
        <EditPresenceListStudents
          workshop={workshop}
          onChange={(event, workshopId, presenceList) =>
            this.changePresenceList(event, workshopId, presenceList)
          }
        />
      ),
    })
      .then((result) => {
        if(result.isConfirmed) {
          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>OK</span>,
            customClass: {
              confirmButton: "confirm-button-class",
              cancelButton: "cancel-button-class",
            },
            icon: "success",
            title: <span style={{ color: "#FFC107" }}>Sucesso!</span>,
            html: <p style={{ color: "#000" }}>A lista de presença foi alterada.</p>,
          })
        }
      })
      .catch((err) => {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        });
      });
  }

  attributePresenceListStudentsPopup(workshop) {
    const finishPresenceList = (savePresence) => {
      this.setState({ savePresence: savePresence });
    }

    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: false,
      title: <span style={{ color: "#FFC107" }}><i className="fa fa-arrow-left fa-xs" onClick={() => this.backToEditWorkshopPopup()} aria-hidden="true"></i> Atribuir Presença</span>,
      html: <AttributePresenceListStudents workshop={workshop} finishPresenceList={finishPresenceList} />,
    })
      .then((result) => {
        if (result.isConfirmed) {
          const execApiCall = this.state.savePresence;
          execApiCall();

          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>OK</span>,
            icon: "success",
            title: <span style={{ color: "#FFC107" }}>Sucesso!</span>,
            html: <p style={{ color: "#000" }}>A lista de presença foi salva.</p>,
          });

          this.setState({ resultPopup: result.value });
        }
      })
      .catch((err) => {
        console.error(err)
       /*  MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        }); */
      });
  }

  async editPowersStudentsPopup(workshop) {    
    const currentClass = JSON.parse(
      window.localStorage.getItem("currentClass")
    );

    const participantData = await DashboardService.getWorkshopByWorkshopTypeId(
      currentClass.code,
      workshop.workshop
    );

    const onChangePower = async (userData) => {
      const formattedUserData =JSON.parse(userData)
      const objIndex = this.state.userData.findIndex(user => user.participantId === formattedUserData.participantId)
      let updatedUserData = [...this.state.userData]
      updatedUserData[objIndex] = {
        ...updatedUserData[objIndex],
        powerId: formattedUserData.powerId
      }
      this.setState({ userData: updatedUserData });
    };

    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCancelButton: false,
      showCloseButton: true,
      title: <span style={{ color: "#FFC107" }}><i className="fa fa-arrow-left fa-xs" onClick={() => this.backToEditWorkshopPopup()} aria-hidden="true"></i> Editar Poderes</span>,
      html: (
        <EditPowersStudents
          workshop={workshop}
          participantData={participantData}
          onChangePower={(userData) => onChangePower(userData)}
          currentClassCode={currentClass.code}
          seedUserData={this.seedUserData}
        />
      ),
    })
      .then(async (result) => {
        this.setState({ resultPopup: result.value });

        if(this.state.userData.some(user => user.powerId === null)) {
          result.isConfirmed = false;

          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>VOLTAR</span>,
            customClass: {
              confirmButton: "confirm-button-class",
              cancelButton: "cancel-button-class",
            },
            icon: "error",
            title: (
              <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
            ),
            html: <p style={{ color: "#000" }}>Preencha os poderes de todos os participantes</p>,
          })
          throw new Error();
        }

        try {
          const alteredParticipants = this.state.userData.filter((data, index) => {
            return this.compareUsersData(data, this.state.firstStateUserData[index], "powers")
          })
  
          if(alteredParticipants.length > 0) {
            alteredParticipants.forEach(async (user) => {
              const code = user.classCode;
              const workshopId = user.workshopId;
              const userValues = {
                userData: `${user.powerId},${user.classCode},${user.participantId},${user.workshopId}`
              }
    
              await ClassService.updateStudentPower(
                code,
                workshopId,
                userValues
              ).catch((err) => result.isConfirmed = false)
            })
    
            await ClassService.completeStep(
              currentClass.code,
              workshop.workshop,
              "powersCompleted"
            );
    
            this.setState({ userData: [] });
    
            if(result.isConfirmed) {
              MySwal.fire({
                confirmButtonColor: "#87BF40",
                confirmButtonText: <span>OK</span>,
                customClass: {
                  confirmButton: "confirm-button-class",
                  cancelButton: "cancel-button-class",
                },
                icon: "success",
                title: <span style={{ color: "#FFC107" }}>Sucesso!</span>,
                html: <p style={{ color: "#000" }}>Os poderes foram alterados.</p>,
              })
            }
          }
         } catch (error) {
          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>VOLTAR</span>,
            icon: "error",
            title: (
              <span style={{ color: "#FFC107" }}>{error.message}</span>
            ),
            html: <p style={{ color: "#000" }}>Ocorreu um erro ao fazer as alterações. Tente novamente!</p>,
          });
         }
        })
      .catch((err) => {
        console.error(err)
        /* if (!this.state.resultPopup) {
          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>VOLTAR</span>,
            icon: "error",
            title: (
              <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
            ),
            html: (
              <p style={{ color: "#000" }}>{err?.response?.body.message}</p>
            ),
          });
        } */
      });
  }

  async editMissionsStudentsPopup(workshop) {
    const currentClass = JSON.parse(
      window.localStorage.getItem("currentClass")
    );

    const participantData = await DashboardService.getWorkshopByWorkshopTypeId(
      currentClass.code,
      workshop.workshop
    );

    const onChangeMission = (userData) => {
      const formattedUserData =JSON.parse(userData)

      const objIndex = this.state.userData.findIndex(user => user.participantId === formattedUserData.participantId)
      let updatedUserData = [...this.state.userData]

      updatedUserData[objIndex] = {
        ...updatedUserData[objIndex],
        missionId: formattedUserData.missionId
      }

      this.setState({ userData: updatedUserData });
    };

    MySwal.fire({
      allowOutsideClick: true,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: false,
      title: <span style={{ color: "#FFC107" }}><i className="fa fa-arrow-left fa-xs" onClick={() => this.backToEditWorkshopPopup()} aria-hidden="true"></i> Editar Missões</span>,
      html: (
        <EditMissionsStudents
          workshop={workshop}
          participantData={participantData}
          onChangeMission={(userData) => onChangeMission(userData)}
          currentClassCode={currentClass.code}
          seedUserData={this.seedUserData}
        />
      ),
    })
      .then(async (result) => {
        this.setState({ resultPopup: result.value });

        if(this.state.userData.some(user => user.missionId === null)) {
          result.isConfirmed = false;
          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>VOLTAR</span>,
            icon: "error",
            title: (
              <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
            ),
            html: <p style={{ color: "#000" }}>Preencha as missões de todos os participantes</p>,
          });
          throw new Error();
        }

       try {
        const alteredParticipants = this.state.userData.filter((data, index) => {
          return this.compareUsersData(data, this.state.firstStateUserData[index], "missions")
        })

        if(alteredParticipants.length > 0) {
          alteredParticipants.forEach(async (user) => {
            const code = user.classCode;
            const workshopId = user.workshopId;
            const userValues = {
              userData: `${user.missionId},${user.classCode},${user.participantId},${user.workshopId}`
            }
  
            await ClassService.updateStudentMission(
              code,
              workshopId,
              userValues
            ).catch((err) => result.isConfirmed = false)
          })
  
          await ClassService.completeStep(
            currentClass.code,
            workshop.workshop,
            "missionsCompleted"
          );
  
          this.setState({ userData: [] });
  
          if(result.isConfirmed) {
            MySwal.fire({
              confirmButtonColor: "#87BF40",
              confirmButtonText: <span>OK</span>,
              customClass: {
                confirmButton: "confirm-button-class",
                cancelButton: "cancel-button-class",
              },
              icon: "success",
              title: <span style={{ color: "#FFC107" }}>Sucesso!</span>,
              html: <p style={{ color: "#000" }}>As missões foram alteradas.</p>,
            })
          }
        }
       } catch (error) {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>{error.message}</span>
          ),
          html: <p style={{ color: "#000" }}>Ocorreu um erro ao fazer as alterações. Tente novamente!</p>,
        });
       }
      })
      .catch((err) => {
        console.error(err)
        /* MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response?.body?.message}</p>,
        }); */
      });
  }

  async editStarsStudentsPopup(workshop) {
    const currentClass = JSON.parse(
      window.localStorage.getItem("currentClass")
    );

    const workshopData = await DashboardService.getWorkshopByWorkshopTypeId(
      currentClass.code,
      workshop.workshop
    );

    const powers = this.state.powers.map(power => {
      return {
        _id: power._id,
        ...power.pt
      }
    })

    const participantsWithPresence = workshopData
      .filter((wk) => {
        return wk.workshops.presenceList?.presence;
      })
      .map((wk) => {
        const presence = wk.workshops.presenceList.presence;
        const poderId = wk.workshops?.participant.powers.find(power => {
          return power.workshop === workshop.workshop
        })?.powerId
        const poder = powers.filter(p => p._id == poderId)[0]?.name
        return {
          ...wk.workshops?.participant,
          poderId,
          poder,
          presence,
          workshop: workshop.workshop
        };
      });

    const participantsWithSelectedMissions = participantsWithPresence.filter(
      (participant) => {
        if (!participant) {
          return;
        }
        const studentsWithMission = participant.missions?.filter((mission) => {
          return mission.workshop === workshop.workshop && mission.missionId;
        });
        return studentsWithMission.length > 0;
      }
    );

    const onChangeStar = async (student) => {
      await ParticipantService.assignStars([student]);

      await ClassService.completeStep(
        currentClass.code,
        workshop.workshop,
        "starsCompleted"
      );

      await ClassService.completeClass({
        code: currentClass.code,
        workshop: workshop.workshop,
        step: "completed",
      });

      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>OK</span>,
        icon: "success",
        title: <span style={{ color: "#FFC107" }}>Sucesso!</span>,
        html: <p style={{ color: "#000" }}>A estrela foi alterada.</p>,
      }).then(() => this.editStarsStudentsPopup(workshop));
    };

    MySwal.fire({
      allowOutsideClick: true,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: false,
      title: <span style={{ color: "#FFC107" }}><i class="fa fa-arrow-left fa-xs" onClick={() => this.backToEditWorkshopPopup()} aria-hidden="true"></i> Editar Estrelas</span>,
      html: (
        <EditStarsStudents
          workshop={workshop}
          participants={participantsWithSelectedMissions}
          onChangeStar={(userData) => onChangeStar(userData)}
        />
      ),
    })
      .then((result) => {
        this.setState({ resultPopup: result.value });
        if(result.isConfirmed) {
          MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>OK</span>,
            customClass: {
              confirmButton: "confirm-button-class",
              cancelButton: "cancel-button-class",
            },
            icon: "success",
            title: <span style={{ color: "#FFC107" }}>Sucesso!</span>,
            html: <p style={{ color: "#000" }}>As estrelas foram alteradas.</p>,
          })
        }
      })
      .catch((err) => {
        console.error(err)
       /*  MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        }); */
      });
  }

  editWorkshopPopup = async (item) => {
    this.setState({ classInfo: item });
    window.localStorage.setItem("currentClass", JSON.stringify(item));
    let workshops = await DashboardService.getPresenceListToEdit(item._id);

    const onClosePopup = () => {      
      if (!window.location.href.includes("dashboard")) {

        const navigator = navigate.bind(this.component);
        navigator({ goBack: true });
      }
    };

    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      onClose: onClosePopup,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: "#FFC107" }}>Editar Turmas</span>,
      html: (
        <EditWorkshops
          name={item.name}
          workshops={workshops}
          onClick={(w, r, e, item) => this.handleEditWorkshops(w, r, e, item)}
        />
      ),
    })
    .then((result) => {
      this.setState({ resultPopup: result.value });
      this.onClosePopup();
    })
      .catch((err) => {
        console.error(err);
        // MySwal.fire({
        //   confirmButtonColor: "#87BF40",
        //   confirmButtonText: <span>VOLTAR</span>,
        //   icon: "error",
        //   title: (
        //     <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
        //   ),
        //   html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        // });
      });
  };

  editParticipantsPopup = async (item) => {
    let participants = await DashboardService.getParticipantsByClassCode(
      item.code
    );

    const onClosePopup = () => {      
      if (!window.location.href.includes("dashboard")) {
        const navigator = navigate.bind(this.component);
        navigator({ goBack: true });
      }
    };

    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      onClose: onClosePopup,
      cancelButtonText: "CANCELAR",
      confirmButtonText: "CONCLUIR",
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: false,
      title: (
        <span style={{ color: "#FFC107" }}>Incluir/Excluir Participantes</span>
      ),
      html: (
        <EditParticipants
          participants={participants}
          name={item.name}
          onClick={(item) => this.editParticipantsPopup(item)}
          turma={item}
        />
      ),
    })
      .then(async () => {
          await this.search(this.state.currentPage, false);
          this.onClosePopup()
      })
      .catch((err) => {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>VOLTAR</span>,
          icon: "error",
          title: (
            <span style={{ color: "#FFC107" }}>Não foi possível editar</span>
          ),
          html: <p style={{ color: "#000" }}>{err.response.body.message}</p>,
        });
      });
  };

  getClasses() {
    let classes = [];
    classes =
      this.state.classes.length &&
      this.state.classes.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.code}</td>
            <td>{item.name}</td>
            <td>
              {item.type === "campaign"
                ? "Modo Campanha"
                : item.type === "workshop"
                ? "Modo Oficina Única"
                : item.type === "campaignLevel1"
                ? "Modo Campanha Aprendiz"
                : "Modo Campanha Especialista"}
            </td>
            <td>{item.instructor ? item.instructor : "-"}</td>
            <td>{item.participants ? item.participants : 0}</td>
            <td>{item.attendance}</td>
            {/* <td><span><i className="fa fa-info-circle"></i></span></td> */}
            <div className="action-btn">
              <div className="container-btn">
                <td>
                  <a
                    className="ml-3 btn-remove"
                    href="javascript:void(0)"
                    onClick={() => this.editWorkshopPopup(item)}
                  >
                    editar turma
                  </a>
                </td>
                {!SecurityService.isMaster() ? (
                  <></>
                ) : (
                  <td>
                    <a
                      className="ml-3 btn-remove"
                      href="javascript:void(0)"
                      onClick={() => this.editClass(item._id)}
                    >
                      editar datas
                    </a>
                  </td>
                )}
              </div>
              <div className="container-btn">
                {!SecurityService.isMaster() ? (
                  <></>
                ) : (
                  <td>
                    <a
                      className="ml-3 btn-remove"
                      href="javascript:void(0)"
                      onClick={() => this.editParticipantsPopup(item)}
                    >
                      adc/del participantes
                    </a>
                  </td>
                )}
                {!SecurityService.isMaster() ? (
                  <></>
                ) : (
                  <td>
                    <a
                      className="ml-3 btn-remove"
                      href="javascript:void(0)"
                      onClick={() => this.removeClass(item.code)}
                    >
                      remover
                    </a>
                  </td>
                )}
              </div>
            </div>
          </tr>
        );
      });
    return classes;
  }

  getListDateEdit(res) {
    this.setState({ dateToEdit: [] });
    this.setState({ worskshopCodeDate: [] });

    return (
      <ul>
        <h2>{res.data[0].nameClass}</h2>
        {res.data.map((item, index) => {
          this.setState({
            dateToEdit: [
              ...this.state.dateToEdit,
              moment(item.dateClass).utc().format("YYYY-MM-DD"),
            ],
            worskshopCodeDate: [
              ...this.state.worskshopCodeDate,
              item.codeWorkshop,
            ],
          });
          return (
            <li key={item.codeWorkshop}>
              <div>
                <label>Data {index + 1}º</label>
                <input
                  type="date"
                  key={item.codeWorkshop}
                  className="form-control"
                  name="dateToEdit"
                  defaultValue={this.state.dateToEdit[index]}
                  onChange={(e) =>
                    this.handleInputChangeEditDate(e, index, item.codeWorkshop)
                  }
                  style={{ display: "inline", margin: 10, width: 300 }}
                />
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({
      currentPage: pageNumber,
    });

    await this.search(pageNumber);
  };

  filter() {
    return {
      instructor: this.state.searchInstructor,
      start:
        this.state.searchDateStart ||
        moment().startOf("year").utc().format("YYYY-MM-DD"),
      end: this.state.searchDateEnd,
      type: this.state.searchClassType,
      state: this.state.searchState,
      country: this.state.searchCountry,
      notstarted: this.state.searchNotStarted,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-card-fullscreen">
          <h2 className="form-title">Relatório</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Data inicial:</label>
                <input
                  type="date"
                  className="form-control"
                  name="searchDateStart"
                  onChange={this.handleInputChange}
                  value={this.state.searchDateStart}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Data final:</label>
                <input
                  type="date"
                  className="form-control"
                  name="searchDateEnd"
                  onChange={this.handleInputChange}
                  value={this.state.searchDateEnd}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Instrutor:</label>
                <select
                  name="searchInstructor"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.searchInstructor}
                >
                  <option value="">Selecione um consultor</option>
                  {this.state.instructors.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Tipo:</label>
                <select
                  name="searchClassType"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.searchClassType}
                >
                  <option value="">Selecione um tipo</option>
                  <option value="workshop">Modo Oficina Única</option>
                  <option value="campaign">Modo Campanha</option>
                  <option value="campaignLevel1">Modo Campanha Aprendiz</option>
                  <option value="campaignLevel2">
                    Modo Campanha Especialista
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Estado:</label>
                <select
                  name="searchState"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.searchState}
                >
                  <option value="">Selecione o estado</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>País:</label>
                <select
                  name="searchCountry"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.searchCountry}
                >
                  <option value="">Selecione o país</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.pais}>
                      {country.pais}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Turmas:</label>
                <select
                  name="searchNotStarted"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.searchNotStarted}
                >
                  <option value="">Iniciadas</option>
                  <option value="true">Não Iniciadas</option>
                </select>
              </div>
            </div>
            <div className="row content-header">
              <div className="col-sm-12">
                <a
                  className="button-full btn-export"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="fa fa-file-excel"></i> relatórios
                </a>
                <button
                  className="button-full btn-save"
                  onClick={() => this.search(1)}
                  type="button"
                >
                  <i className="fa fa-search"></i> Pesquisar
                </button>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>

          <h2 className="form-title">Lista de turmas</h2>
          <Loading status={this.state.loading}>
            <table className="table table-responsive table-hover table-striped">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <th>Instrutor</th>
                  <th>Participantes</th>
                  <th>Atendimentos</th>
                  {/* <th className="info"></th> */}
                  {/* {!SecurityService.isMaster() ? (
                    <></>
                  ) : ( */}
                  <th className="actions">Ação</th>
                  {/* )} */}
                </tr>
              </thead>
              <tbody>
                {this.state.classes.length
                  ? this.getClasses()
                  : "Nenhuma turma encontrada"}
              </tbody>
            </table>

            <div className="pagination-content">
              <Pagination
                hideFirstLastPages
                pageRangeDisplayed={10}
                activePage={this.state.currentPage}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalItens}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>

            <ReportsModal filter={this.filter()} />
          </Loading>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
