import React, { Component } from 'react';
import './Power-create.scss';

import PowerService from '../../../services/power.service';
import AwsService from '../../../services/aws.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class PowerNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      namePortuguese: '',
      descriptionPortuguese: '',
      nameEnglish: '',
      descriptionEnglish: '',
      nameSpanish: '',
      descriptionSpanish: '',
      capaPortuguese: '',
      capaEnglish: '',
      capaSpanish: '',
      AwsService: new AwsService()
    }
  }

  componentDidMount = () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
  };

  handleInputChange = async (e) => {
    const target = e.target;

    await this.setState({
      [target.name]: target.value
    });
  }

  create = async () => {
    console.log(this.state)
    if (
      this.state.namePortuguese == "" ||
      this.state.descriptionPortuguese == "" ||
      this.state.nameEnglish == "" ||
      this.state.descriptionEnglish == "" ||
      this.state.nameSpanish == "" ||
      this.state.descriptionSpanish == "" ||
      this.state.capaPortuguese == "" ||
      this.state.capaEnglish == "" ||
      this.state.capaSpanish == ""
    ) {
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: <span>OK</span>,
        icon: 'error',
        title: <p>Há campos obrigatórios que não foram preenchidos</p>
      })
    } else {

      const power = {
        pt: {
          name: this.state.namePortuguese,
          description: this.state.descriptionPortuguese
        },
        en: {
          name: this.state.nameEnglish,
          description: this.state.descriptionEnglish
        },
        es: {
          name: this.state.nameSpanish,
          description: this.state.descriptionSpanish
        }
      }

      try {
        let res = await PowerService.create(power);
        if (res._id) {
          this.upload(res._id);
          MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>OK</span>,
            icon: 'success',
            title: <p>Poder cadastrado com sucesso</p>
          })
          this.props.history.push('/powers')
        }
      } catch (err) {
        console.log(err)
        MySwal.fire({
          confirmButtonColor: '#87BF40',
          confirmButtonText: <span>Tentar novamente</span>,
          icon: 'error',
          title: <p>Erro ao cadastrar poder.</p>
        })
      }
    }
  }

  selectFile = async (e) => {
    const input = e.target;

    if (input.id == 'capaPortuguese')
      await this.setState({ capaPortuguese: input.files })
    else if (input.id == 'capaEnglish')
      await this.setState({ capaEnglish: input.files })
    else if (input.if == "capaSpanish")
      await this.setState({ capaSpanish: input.files})

    console.log(this.state)
  }

  upload = async(id) => {
    console.log(id)
    console.log(this.state.capaPortuguese[0])
    this.state.AwsService.uploadfile(this.state.capaPortuguese[0], 
        `capa_pt.${this.state.capaPortuguese[0].name.split('.')[this.state.capaPortuguese[0].name.split('.').length-1]}`, id);
    this.state.AwsService.uploadfile(this.state.capaEnglish[0], 
        `capa_en.${this.state.capaEnglish[0].name.split('.')[this.state.capaEnglish[0].name.split('.').length-1]}`, id);
    this.state.AwsService.uploadfile(this.state.capaSpanish[0], 
        `capa_es.${this.state.capaSpanish[0].name.split('.')[this.state.capaSpanish[0].name.split('.').length-1]}`, id);

  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Novo poder</h1>
        </div>

        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                    <label>Nome em português:<span className="highlighter">*</span></label>
                    <input type="text" name="namePortuguese" className="form-control" required
                        onChange={this.handleInputChange} value={this.state.namePortuguese} />
                </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Nome em inglês:<span className="highlighter">*</span></label>
                        <input type="text" name="nameEnglish" className="form-control" required
                            onChange={this.handleInputChange} value={this.state.nameEnglish} />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Nome em espanhol:<span className="highlighter">*</span></label>
                        <input type="text" name="nameSpanish" className="form-control" required
                            onChange={this.handleInputChange} value={this.state.nameSpanish} />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Descrição em português:<span className="highlighter">*</span></label>
                        <textarea name="descriptionPortuguese" className="form-control" required
                            onChange={this.handleInputChange} value={this.state.descriptionPortuguese}></textarea>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Descrição em inglês:<span className="highlighter">*</span></label>
                        <textarea name="descriptionEnglish" className="form-control" required
                            onChange={this.handleInputChange} value={this.state.descriptionEnglish}></textarea>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Descrição em espanhol:<span className="highlighter">*</span></label>
                        <textarea name="descriptionSpanish" className="form-control" required
                            onChange={this.handleInputChange} value={this.state.descriptionSpanish}></textarea>
                    </div>
                </div>

                <div className="col-md-12 mb-4">
                  <h5>
                    Capa em português<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="capaPortuguese" name="capaPortuguese" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12">
                  <h5>
                    Capa em inglês<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="capaEnglish" name="capaEnglish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12">
                  <h5>
                    Capa em espanhol<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="capaSpanish" name="capaSpanish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
              </div>
              <hr />
              <div className="row row-center">
                <div className="col-8">
                  <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="button-full btn-save"
                    type="button"
                    onClick={() => this.create()}
                  >
                    <i className="fa fa-check"></i> Cadastrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PowerNew;