import React from "react";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import { formatDate } from "../../../shared/utils/formatDate";
import handleToggle from "../utils/handleToggle";
import popupChooseNewExpireDate from "../utils/popupChooseNewExpireDate";

const Item = ({ item, index, setInstructors }) => {
      let createdAt = item.createdAt? 
        formatDate(item.createdAt,"dd/MM/yyyy")
        : "";
      let licenseExpireIn = item.institution.licenseExpireIn? 
        formatDate(item.institution.licenseExpireIn, "dd/MM/yyyy")
        : "";
      let licenseExpireInUsaPattern = item.institution.licenseExpireIn? 
        formatDate(item.institution.licenseExpireIn, "yyyy-MM-dd")
        : "";
      let dateDistance = item.institution.licenseExpireIn? 
        formatDistance(
            new Date(),
            new Date(item.institution.licenseExpireIn),
            {
                locale: ptBR,
            }
        )
        : "";

    return (
        <tr key={index}>
          <td>
            {index + 1}. <b>{item.name.toUpperCase()}</b>
          </td>
          <td>{item.email}</td>
          <td>{item.institution ? item.institution.name : ""}</td>
          <th>{createdAt}</th>
          <th>
            {licenseExpireIn}
            <br></br>
            {item.institution.licenseExpireIn <=
             new Date().toISOString()? 
              dateDistance + " atrás"
              : dateDistance}
            <br></br>
            <button
              className="button btn-save btn-edit"
              onClick={() =>
                popupChooseNewExpireDate(item, licenseExpireInUsaPattern, setInstructors)
              }
              type="button"
            >
              Editar
            </button>
          </th>
          <td>{item.institution?.license}</td>
          <td>
            {item.institution.licenseExpireIn <= new Date().toISOString() ?               
              <>
                <div data-tip data-for="toggle-license">
                  <Toggle                  
                  id={"toggle-license-active"}
                  defaultChecked={item.institution?.licenseActive}
                  disabled                  
                />
                </div>                
                <ReactTooltip
                  id="toggle-license"
                  place="top"
                  effect="solid"
                >
                  Para ativar a licença de um instrutor, a data de expiração da
                  licença deve ser posterior ao dia de hoje!
                </ReactTooltip>
              </>
             : 
              <Toggle
                id={item._id}
                defaultChecked={item.institution?.licenseActive}
                onChange={handleToggle}
              />
            }
          </td>
        </tr>
    )
}

export default Item;