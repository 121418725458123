import React, { Component } from "react";
import Toggle from "react-toggle";
import "./Mission.scss";
import ReactExport from "react-data-export";

import MissionService from "../../services/mission.service";
import PowerService from "../../services/power.service";
import SecurityService from "../../services/security.service";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AuthContext } from "../../context/authContext";
import { navigate, navigateUsingAchor } from "../../services/navigation.service";
const MySwal = withReactContent(Swal)

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Mission extends Component {
  static contextType = AuthContext

  constructor() {
    super();
    this.state = {
      missions: []
    };
  }

  componentWillMount = async () => {
    let missions = await MissionService.getAll();
    const powers = await PowerService.getAll();

    missions = missions.map(mission => {
      let powerAux = powers.find(p => p._id === mission.power)
      if (powerAux)
        mission.powerName = powerAux.pt ? powerAux.pt.name : '-';
      return mission;
    })

    missions.sort((a, b) => {
      if (a.powerName > b.powerName) return 1;
      if (a.powerName < b.powerName) return -1;
      return 0;
    })

    await this.setState({
      missions: missions
    });
  };

  componentDidMount = () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
  };

  styleCells() {
    const columns = [
      {
        title: "Nome",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Poder",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Contexto",
        width: { wch: 100 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "O que fazer",
        width: { wch: 200 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Resultado",
        width: { wch: 100 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      },
      {
        title: "Dica",
        width: { wch: 200 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } }
        }
      }
    ];
    let data = this.state.missions.map(item => {
      return [
        { value: item.pt ? item.pt.name : '-' },
        { value: item.powerName ? item.powerName : '-' },
        { value: item.pt ? item.pt.context : '-' },
        { value: item.pt ? item.pt.toDo : '-' },
        { value: item.pt ? item.pt.result : '-' },
        { value: item.pt ? item.pt.tip : '-' }
      ];
    });

    return [
      {
        columns,
        data
      }
    ];
  }

  changeActive = async e => {
    const id = e.target.id;
    const status = e.target.checked;
    MissionService.changeActive(id, { status }).catch(() => {})
  };

  removeMission = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir uma missão, ela não aparecerá mais para os usuários e não poderá ser mais utilizada no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Esta missão ainda poderá ser restaurada</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await MissionService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }

  renderMissions() {
    let missions = [];
    missions = this.state.missions.map((item, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.pt.name}</td>
          <td>{item.powerName}</td>
          <td>
            <Toggle
              id={item._id}
              defaultChecked={item.active}
              onChange={this.changeActive.bind(this)}
            />
          </td>
          <td>
            <a 
              className="btn-edit" 
              href={`/missions/${item._id}`}
              onClick={(e) => navigateUsingAchor(e, this)}
            >
              editar
            </a>
            {/* {SecurityService.isMaster() ?
              <button
                className="ml-3 btn-remove"
                onClick={() => this.removeMission(item._id)}
              >
                remover
              </button> : <></>
            } */}
          </td>
        </tr>
      );
    });
    return missions;
  }

  renderButtonExportExcel() {
    if (this.state.missions.length) {
      return (
        <ExcelFile
          element={
            <button className="button-full btn-export">
              <i className="fa fa-file-excel"></i> exportar
            </button>
          }
          filename={"missoes_" + new Date().getTime()}
        >
          <ExcelSheet dataSet={this.styleCells()} name="Missoes"></ExcelSheet>
        </ExcelFile>
      );
    }
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Missões</h1>
        </div>

        <div className="row content-header">
          <h3 className="list-title">Lista de missões</h3>
          <div className="row">
            <a 
              href="/missions/create" 
              className="button-full btn-create m-right"
              onClick={(e) => navigateUsingAchor(e, this)}
            >
              <i className="fa fa-plus"></i> criar missão
            </a>
            {this.renderButtonExportExcel()}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-responsive table-hover table-striped">
              <thead>
                <tr>
                  <th>nº</th>
                  <th>Nome</th>
                  <th>Poder</th>
                  <th>Ativa</th>
                  <th className="actions">Ações</th>
                </tr>
              </thead>
              <tbody>{this.renderMissions()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Mission;
