import React, { Component } from 'react';
import './Dashboard.scss';

import Ranking from './Ranking/Ranking';
import Classes from './Classes/Classes';

import DashboardService from '../../services/dashboard.service';

class Dashboard extends Component {
  constructor() {
    super()
    this.state = {
      participants: 0,
      workshops: 0,
      campaigns: 0,
      quizzes: 0,
      powers: 0,
      missions: 0,
    }
  }

  componentWillMount = async () => {
    const general = await DashboardService.general();
    await this.setState({
      participants: general.participants,
      workshops: general.workshops,
      campaigns: general.campaigns,
      quizzes: general.quiz,
      powers: general.power,
      missions: general.mission
    })
  }

  componentDidMount = () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
};

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Dashboard</h1>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="dashboard-block bg-green">
                  <div className="row">
                    <div className="col-sm-4 text-center">
                      <i className="fas fa-users"></i>
                    </div>
                    <div className="col-sm-8">
                      <span className="dashboard-number">{this.state.participants}</span>
                      <span className="dashboard-text">Participantes</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="dashboard-block bg-yellow">
                  <div className="row">
                    <div className="col-sm-4 text-center">
                      <i className="fas fa-laptop-code"></i>
                    </div>
                    <div className="col-sm-8">
                      <span className="dashboard-number">{this.state.workshops}/{this.state.campaigns}</span>
                      <span className="dashboard-text">Oficinas/<br />Campanhas</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="dashboard-block bg-orange">
                  <div className="row">
                    <div className="col-sm-4 text-center">
                      <i className="fas fa-puzzle-piece"></i>
                    </div>
                    <div className="col-sm-8">
                      <span className="dashboard-number">{this.state.quizzes}</span>
                      <span className="dashboard-text">Quizzes</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="dashboard-block bg-blue">
                  <div className="row">
                    <div className="col-sm-4 text-center">
                      <i className="fa fa-list"></i>
                    </div>
                    <div className="col-sm-8">
                      <span className="dashboard-number">{this.state.powers}/{this.state.missions}</span>
                      <span className="dashboard-text">Poderes/<br />Missões</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div>
            <Classes />
          </div>
          <div>
            <Ranking />
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;