import React, { Component } from 'react';
import './Workshop-create.scss';

// import ParticipantService from '../../services/instructor.service';
import WorkshopService from '../../../services/workshop.service';
import AwsService from '../../../services/aws.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class WorkshopNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      namePortuguese: '',
      descriptionPortuguese: '',
      nameEnglish: '',
      descriptionEnglish: '',
      nameSpanish: '',
      descriptionSpanish: '',
      fileApresentationPortuguese: '',
      fileHandsonPortuguese: '',
      fileResultPortuguese: '',
      fileApresentationEnglish: '',
      fileHandsonEnglish: '',
      fileResultEnglish: '',
      fileApresentationSpanish: '',
      fileHandsonSpanish: '',
      fileResultSpanish: '',
      AwsService: new AwsService()
    }
  }

  componentDidMount = () => {
    localStorage.removeItem('instructor-search-filter')
    localStorage.removeItem('instructor-current-page')
  };

  handleInputChange = async (e) => {
    const target = e.target;

    await this.setState({
      [target.name]: target.value
    });
  }

  create = async () => {
    console.log(this.state)
    if (this.state.namePortuguese == '' || this.state.nameEnglish == '' || this.state.nameSpanish == ''
                                        || this.state.descriptionPortuguese == ''
                                        || this.state.descriptionEnglish == ''
                                        || this.state.descriptionSpanish == ''
                                        || this.state.fileApresentationPortuguese == ''
                                        || this.state.fileHandsonPortuguese == ''
                                        || this.state.fileResultPortuguese == ''
                                        || this.state.fileApresentationEnglish == ''
                                        || this.state.fileHandsonEnglish == ''
                                        || this.state.fileResultEnglish == ''
                                        || this.state.fileApresentationSpanish == ''
                                        || this.state.fileHandsonSpanish == ''
                                        || this.state.fileResultSpanish == '') {
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: <span>OK</span>,
        icon: 'error',
        title: <p>Há campos obrigatórios que não foram preenchidos</p>
      })
    } else {

      const workshop = {
        pt: {
          name: this.state.namePortuguese,
          description: this.state.descriptionPortuguese
        },
        en: {
          name: this.state.nameEnglish,
          description: this.state.descriptionEnglish
        },
        es: {
          name: this.state.nameSpanish,
          description: this.state.descriptionSpanish
        }
      }

      try {
        let res = await WorkshopService.create(workshop);
        if (res._id) {
          this.upload(res._id);
          MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>OK</span>,
            icon: 'success',
            title: <p>Oficina cadastrada com sucesso</p>
          })
          this.props.history.push('/workshop')
        }
      } catch (err) {
        console.log(err)
        MySwal.fire({
          confirmButtonColor: '#87BF40',
          confirmButtonText: <span>Tentar novamente</span>,
          icon: 'error',
          title: <p>Erro ao cadastrar oficina.</p>
        })
      }
    }
  }

  selectFile = async (e) => {
    const input = e.target;

    if (input.id == 'apresentationPortuguese')
      await this.setState({ fileApresentationPortuguese: input.files })
    else if (input.id == 'handsonPortuguese')
      await this.setState({ fileHandsonPortuguese: input.files })
    else if (input.id == 'resultPortuguese')
      await this.setState({ fileResultPortuguese: input.files })
    else if (input.id == 'apresentationEnglish')
      await this.setState({ fileApresentationEnglish: input.files })
    else if (input.id == 'handsonEnglish')
      await this.setState({ fileHandsonEnglish: input.files })
    else if (input.id == 'resultEnglish')
      await this.setState({ fileResultEnglish: input.files })
    else if (input.id == 'apresentationSpanish')
      await this.setState({ fileApresentationSpanish: input.files })
    else if (input.id == 'handsonSpanish')
      await this.setState({ fileHandsonSpanish: input.files })
    else if (input.id == 'resultSpanish')
      await this.setState({ fileResultSpanish: input.files })

    console.log(this.state)
  }

  upload = async (id) => {
    this.state.AwsService.uploadfile(this.state.fileApresentationPortuguese[0],
      `apresentation_pt.${this.state.fileApresentationPortuguese[0].name.split('.')[this.state.fileApresentationPortuguese[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileHandsonPortuguese[0],
      `handson_pt.${this.state.fileHandsonPortuguese[0].name.split('.')[this.state.fileHandsonPortuguese[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileResultPortuguese[0],
      `result_pt.${this.state.fileResultPortuguese[0].name.split('.')[this.state.fileResultPortuguese[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileApresentationEnglish[0],
      `apresentation_en.${this.state.fileApresentationEnglish[0].name.split('.')[this.state.fileApresentationEnglish[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileHandsonEnglish[0],
      `handson_en.${this.state.fileHandsonEnglish[0].name.split('.')[this.state.fileHandsonEnglish[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileResultEnglish[0],
      `result_en.${this.state.fileResultEnglish[0].name.split('.')[this.state.fileResultEnglish[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileApresentationSpanish[0],
      `apresentation_es.${this.state.fileApresentationSpanish[0].name.split('.')[this.state.fileApresentationSpanish[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileHandsonSpanish[0],
      `handson_es.${this.state.fileHandsonSpanish[0].name.split('.')[this.state.fileHandsonSpanish[0].name.split('.').length - 1]}`, id);
    this.state.AwsService.uploadfile(this.state.fileResultSpanish[0],
      `result_es.${this.state.fileResultSpanish[0].name.split('.')[this.state.fileResultSpanish[0].name.split('.').length - 1]}`, id);
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Nova oficina</h1>
        </div>

        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nome em português:<span className="highlighter">*</span></label>
                    <input type="text" name="namePortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.namePortuguese} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nome em inglês:<span className="highlighter">*</span></label>
                    <input type="text" name="nameEnglish" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.nameEnglish} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nome em espanhol:<span className="highlighter">*</span></label>
                    <input type="text" name="nameSpanish" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.nameSpanish} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Descrição em português:<span className="highlighter">*</span></label>
                    <input type="text" name="descriptionPortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.descriptionPortuguese} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Descrição em inglês:<span className="highlighter">*</span></label>
                    <input type="text" name="descriptionEnglish" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.descriptionEnglish} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Descrição em espanhol:<span className="highlighter">*</span></label>
                    <input type="text" name="descriptionSpanish" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.descriptionSpanish} />
                  </div>
                </div>

                <div className="col-md-12 mb-4">
                  <h5>
                    Apresentação em português<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="apresentationPortuguese" name="apresentationPortuguese" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Apresentação em inglês<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="apresentationEnglish" name="apresentationEnglish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Apresentação em espanhol<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="apresentationSpanish" name="apresentationSpanish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Mão na massa em português<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="handsonPortuguese" name="handsonPortuguese" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Mão na massa em inglês<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="handsonEnglish" name="handsonEnglish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Mão na massa em espanhol<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="handsonSpanish" name="handsonSpanish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Resultados em português<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="resultPortuguese" name="resultPortuguese" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Resultados em inglês<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="resultEnglish" name="resultEnglish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
                <div className="col-md-12 mb-4">
                  <h5>
                    Resultados em espanhol<span className="highlighter">*</span>
                  </h5>
                  <input type="file" id="resultSpanish" name="resultSpanish" required accept="image/png"
                    onChange={this.selectFile.bind(this)} />
                </div>
              </div>
              <hr />

              <div className="row row-center">
                <div className="col-8">
                  <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="button-full btn-save"
                    onClick={() => this.create()}
                    type="button"
                  >
                    <i className="fa fa-check"></i> Cadastrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkshopNew;