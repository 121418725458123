import './License.scss';
import React, { useEffect, useRef, useState } from 'react';
import ReactExport from 'react-data-export';
import Pagination from 'react-js-pagination';
import InstructorService from '../../services/instructor.service';
import Loading from '../Loading/Loading';
import List from './components/List';
import styleCells from './utils/styleCells';
import FilterDetails from './components/FilterDetails';
import sortInstructorsByDate from './utils/sortInstructorsByDate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const License = () => {
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageToQuery, setCurrentPageToQuery] = useState(0);

  const [search, setSearch] = useState("");
  const [initialCreationDate, setInitialCreationDate] = useState("");
  const [initialExpirationDate, setInitialExpirationDate] = useState("");
  const [activeLicense, setActiveLicense] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");

  const [data, setData] = useState([]);
  const [actualInstructor, setActualInstructor] = useState(null);

  const startIndex = currentPage * itemsPerPage;
  const startIndexToQuery = currentPageToQuery * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const endIndexToQuery = startIndexToQuery + itemsPerPage;
  const currentItems =
    data.length === 0
      ? instructors.slice(startIndex, endIndex)
      : data?.result.slice(startIndex, endIndex);

  const searchRef = useRef(null);
  const licenseRef = useRef(null);
  const dataRef = useRef(null);
  const dataInstructor = useRef(null);

  useEffect(() => {
      localStorage.removeItem('instructor-search-filter')
      localStorage.removeItem('instructor-current-page')
  }, [])

  useEffect(() => {
    const getlAllInstructors = async () => {
      const instructors = await InstructorService.getAll();
      setInstructors(instructors);
      setLoading(false);
    };
    getlAllInstructors();
  }, [itemsPerPage]);

  useEffect(() => {
    searchRef.current = search;
  }, [search, licenseNumber]);

  useEffect(() => {
    licenseRef.current = search;
  }, [licenseNumber]);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    dataInstructor.current = data;
  }, [actualInstructor]);

  const handleInputChange = async (e) => {
    setSearch(e);
  };

  const filter = () => {
    const data = {
      instructor:
        searchRef?.current?.length === 36 &&
        searchRef?.current.includes("-") &&
        searchRef?.current.split("-")[0].length === 8 &&
        searchRef?.current.split("-")[1].length === 4 &&
        searchRef?.current.split("-")[2].length === 4 &&
        searchRef?.current.split("-")[3].length === 4 &&
        searchRef?.current.split("-")[4].length === 12
          ? undefined
          : search,
      initialCreationDate: initialCreationDate,
      initialExpirationDate: initialExpirationDate,
      active: activeLicense,
      license:
        searchRef?.current?.length === 36 &&
        searchRef?.current.includes("-") &&
        searchRef?.current.split("-")[0].length === 8 &&
        searchRef?.current.split("-")[1].length === 4 &&
        searchRef?.current.split("-")[2].length === 4 &&
        searchRef?.current.split("-")[3].length === 4 &&
        searchRef?.current.split("-")[4].length === 12
          ? search
          : undefined,
      email: searchRef?.current.includes("@") ? search : undefined,
    };
    if (data.email) data.instructor = null;
    return data;
  };

  const handleSearch = async () => {
    setLoading(true);

    const query = filter();
    const res = await InstructorService.search(query);
    const sortType = initialExpirationDate? 'expirationDate' : 'creationDate'

    setData(sortInstructorsByDate(res, sortType));
    setSearch("");
    setInitialCreationDate("");
    setInitialExpirationDate("");
    setActiveLicense("");
    setLoading(false);

    return res;
  };

  return (
    <div className="content-card">
      <div className="row page-title">
        <h1>Licenças</h1>
      </div>

      <div className="row">
        <div className="search-container">
          <h4 className="list-title">Pesquisar</h4>

          <div className="search-container-input">
            <input
              type="text"
              className="form-control wt-22rem"
              placeholder="Pesquisar por nome, email ou licença..."
              value={search}
              onChange={(event) => handleInputChange(event.target.value)}
            />
          </div>
        </div>

        <div className="date-container" >
          <h4 className="list-title">
            Filtrar por data de criação
            <FilterDetails
              name="filter-by-creation-date"
              message="Obtém todos os instrutores criados desde o dia definido"
            />
          </h4>
          <input
            type="date"
            className="form-control wt-14rem"
            value={initialCreationDate}
            onChange={(e) => setInitialCreationDate(e.target.value)}
          />
        </div>

        <div className="date-container">
          <h4 className="list-title">
            Filtrar por data de expiração
            <FilterDetails
              name="filter-by-expiration-date"
              message="Obtém todos os instrutores cujas licenças têm data de expiração até o dia definido"
            />
          </h4>
          <input
            type="date"
            className="form-control wt-14rem"
            value={initialExpirationDate}
            onChange={(e) => setInitialExpirationDate(e.target.value)}
          />
        </div>

        <div className="status-container">
          <h4 className="list-title">Filtrar por status</h4>

          <select
            value={activeLicense}
            onChange={(e) => setActiveLicense(e.target.value)}
          >
            <option default>Selecione uma opção</option>
            <option value={true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
        </div>

        <div className="counter-container">
          <h4 className="list-title">Exibição</h4>

          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
          >
            <option value="20">20 itens</option>
            <option value="40">40 itens</option>
            <option value="60">60 itens</option>
            <option value="80">80 itens</option>
            <option value="100">100 itens</option>
          </select>
        </div>

        <div className="row content-header">
          <div className="button-container">
            <button
              className="button-full btn-save"
              onClick={handleSearch}
              type="button"
            >
              <i className="fa fa-search"></i> Pesquisar
            </button>
          </div>

          <ExcelFile
            element={
              <button className="button-full btn-export">
                <i className="fa fa-file-excel"></i> exportar
              </button>
            }
            filename={"licencas-instrutores"}
          >
            <ExcelSheet dataSet={styleCells(instructors)} name="Instrutores"></ExcelSheet>
          </ExcelFile>
        </div>
      </div>

      <Loading status={loading}>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-responsive table-hover table-striped mt-1rem">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Instituição</th>
                  <th>Criado em</th>
                  <th>Expira em</th>
                  <th>Licença</th>
                  <th>Ativo</th>
                </tr>
              </thead>

              <tbody>
                <List
                  itemsPerPage={itemsPerPage}
                  startIndexToQuery={startIndexToQuery}
                  endIndexToQuery={endIndexToQuery}
                  currentItems={currentItems}
                  setInstructors={setInstructors}
                />
              </tbody>
            </table>

            <div className="pagination-content">
              <Pagination
                hideFirstLastPages
                pageRangeDisplayed={10}
                activePage={currentPage === 0 ? currentPage + 1 : currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={
                  !data?.result ? instructors.length : data?.result?.length
                }
                onChange={(e) => {
                  setCurrentPage(e);
                  if (data?.result) setCurrentPageToQuery(e - 1);
                }}
              />
            </div>
          </div>
        </div>
      </Loading>
    </div>
  );
};

export default License;
